import React, { useEffect, useContext } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { ListItem } from "@material-ui/core";
import PrimaryButton from "../../atoms/PrimaryButton";
import { Store } from "@material-ui/icons";
import GlobalContext from "../../../context/GlobalContext";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import ClearIcon from "@material-ui/icons/Clear";
import { ModalContext } from "../../../context/ModalContext";
import TermsPopup from "../../../Themes/Theme1/Popups/TermsPopup";
import naaLogo from "../../../assets/images/naaLogo.svg";
import myNextFoot from "../../../assets/images/myNextFoot.svg";
import thirdyLogo from "../../../assets/images/thirdyLogo.svg";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const Footer = ({ store_data }) => {
  const { storeDetails } = useContext(GlobalContext);
  const [open, setOpen] = React.useState(false);
  const { isTermsOpen, handleTermsPopup } = useContext(ModalContext);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (
      window &&
      document &&
      storeDetails?.store?.storeDetails?.hubspot_portal_id &&
      storeDetails?.store?.storeDetails?.hubspot_form_id
    ) {
      const script = document.createElement("script");
      const body = document.getElementsByTagName("body")[0];
      script.src = "//js.hsforms.net/forms/v2.js";
      body.appendChild(script);
      script.addEventListener("load", () => {
        window.hbspt.forms.create({
          region: "na1",
          portalId: storeDetails?.store?.storeDetails?.hubspot_portal_id || "",
          formId: storeDetails?.store?.storeDetails?.hubspot_form_id || "",
          target: "#hubspotForm",
        });
      });
    }
  }, []);

  return (
    <footer>
      <div className="footer">
        <div className="container">
          <div className="footerView">
            <div className="footerOne pb-4">
              <div className="naaLogoMobView">
                <img src={naaLogo} alt="Logo" />
              </div>

              <div className="footLogoCont">
                {/* // src={}
                  //   store_data?.store?.id
                  //     ? `${process.env.REACT_APP_IMAGE_URL}uploads/site_logos/site_${store_data?.store?.id}/${store_data?.store?.logo}`
                  //     : global.logo_path || "/images/mylogo.png"
                  // } */}

                <img src={myNextFoot} alt="Logo" />
              </div>
              <div className="naaLogo">
                <img src={naaLogo} alt="Logo" />
              </div>
              <div className="thirdLogo">
                {" "}
                <img src={thirdyLogo} alt="Logo" />
              </div>
            </div>
            <div className="footerTwo pt-4">
              <Link to="/faq">FAQs</Link>
              <Link to="/contact_us">Contact Us</Link>
              <Link to="/about_us">About Us</Link>
              <Link button to="" onClick={handleTermsPopup}>
                Terms Of Use
              </Link>
              {/* <Link to="/auction_terms">Auction Terms</Link> */}
              <Link to="/privacy_policies">Privacy Policy</Link>
            </div>
            {/* <ul className="list-inline d-flex flex-column">
              <li className="list-inline-item">
                <Link to="/buyers">Buyers</Link>
              </li>
              <li className="list-inline-item">
                <Link to="/sellers">Sellers</Link>
              </li>
              <li className="list-inline-item">
                <Link to="/about_us">About Us</Link>
              </li>
              <li className="list-inline-item">
                <Link to="/contact_us">Contact Us</Link>
              </li>
            </ul>
            <ul className="list-inline d-flex flex-column">
              <li className="list-inline-item">
                <Link to="/faq">FAQs</Link>
              </li>
              <li className="list-inline-item">
                <Link to="/privacy_policy">Privacy Policy</Link>
              </li>
              <li className="list-inline-item">
                <Link onClick={handleOpen}>Terms Of Service</Link>
              </li>
            </ul> */}
            {/* <div className="contactServ">
              <h5 className="title"> Contact Us:</h5>
              <p>+123456789</p>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <a href="" target="_blank">
                    <img src="/images/facebook.svg" className="socialLogo" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="" target="_blank">
                    <img src="/images/twitter.svg" className="socialLogo" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="" target="_blank">
                    <img src="/images/youtube.svg" className="socialLogo" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="" target="_blank">
                    <img src="/images/instagram.svg" className="socialLogo" />
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
          <div class="footerTwoMobview">
            <Link to="/faq">FAQs</Link>
            <Link to="/contact_us">Contact Us</Link>
            <Link to="/about_us">About Us</Link>
            <Link button to="" onClick={handleTermsPopup}>
              Terms Of Use
            </Link>
            {/* <Link to="/auction_terms">Auction Terms</Link> */}
            <Link to="/privacy_policies">Privacy Policy</Link>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center copy-right">
              <span>© {new Date().getFullYear()} Copyright &</span>
              <span>&nbsp;&nbsp;All Rights Reserved&nbsp;&nbsp;</span>
            </div>
          </div>
        </div>
      </div>
      {isTermsOpen && <TermsPopup />}
    </footer>
  );
};
export default Footer;
