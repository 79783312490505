import { configureStore, combineReducers } from "@reduxjs/toolkit";
import auctionReducer from "./slices/auctions";
import wishlitReducer from "./slices/wishlist";
import searchReducer from "./slices/search";
import contactReducer from "./slices/contact";

const reducer = combineReducers({
  auctions: auctionReducer,
  wishlist: wishlitReducer,
  search: searchReducer,
  contact: contactReducer,
});

const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

if (!store) {
  console.error("Store creation failed!");
}

export default store;
