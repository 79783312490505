import React, { useState, useMemo, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import CheckBox from "../../atoms/CheckBox";
import "./bidding-pop-up.css";
import { setDecimalPoint } from "../../../utils";
import { bidding } from "../../../API/propertyDetails";
import { toast } from "react-toastify";
import GlobalContext from "../../../context/GlobalContext";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";
import Timer from "../Timer/timer";
import { ModalContext } from "../../../context/ModalContext";
import TermsPopup from "../../../Themes/Theme1/Popups/TermsPopup";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "243px",
    marginBottom: "5px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  mobileFormControl: {
    margin: theme.spacing(1),
    width: "163px",
    marginBottom: "5px",
  },
  formControldiv: {
    width: "250px",
    marginBottom: "3px",
  },
  mobileFormControldiv: {
    width: "170px",
    marginBottom: "3px",
  },
}));

const BiddingForm = ({
  data,
  nextBidAmount,
  setNextBidAmount,
  seyProductDetails,
  handleClose,
  timer,
  limit,
  customerid,
  width,
  isMobile,
  myClientsList,
  myAgent,
  from,
  setSubmittedPracticeOffer,
  submittedPracticeOffer,
  highestBidder,
}) => {
  const { id } = data;
  const [biddingAmount, setBiddingAmount] = useState("");
  const [terms, setTerms] = useState(false);
  const [sellerTerms, setSellerTerms] = useState(false);
  const [clientOptions, setClientOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState({
    id: "-1",
    first_name: "Buyer does not have a Ruumr account",
    last_name: "",
    email: "",
    property_id: "",
    purchase_power: "",
  });
  const [adminCommision, setAdminCommision] = useState(false);
  const [error, setError] = useState("");
  const [clientError, setClientError] = useState("");
  const [ruumrFeeChecked, setRuumrFeeChecked] = useState(false);
  const { userDetails } = useContext(GlobalContext);
  const classes = useStyles();
  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");
  const { isTermsOpen, handleTermsPopup } = useContext(ModalContext);
  useEffect(() => {
    if (myClientsList) {
      if (!userDetails.get.customerid || userDetails.get.customerid === "") {
        setClientOptions(myClientsList);
      } else {
        setClientOptions([
          ...myClientsList,
          {
            id: "-1",
            first_name: "Buyer does not have a Ruumr account",
            last_name: "",
            email: "",
            property_id: "",
            purchase_power: "",
          },
        ]);
      }
    }
  }, [myClientsList]);

  const changeText = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, "").replace(/,/g, "");
    setBiddingAmount((prevData) =>
      !isNaN(value) && value <= 99999999 ? value : prevData,
    );
  };
  const doBidding = async (e) => {
    if (from === "practice") {
      if (
        biddingAmount &&
        biddingAmount >=
          (data?.enable_start_price && data?.bidcount == 0
            ? data?.sprice
            : nextBidAmount)
      ) {
        toast.success("Bid Submitted Successfully", {
          toastId: "success",
        });
        handleClose();
        setSubmittedPracticeOffer([
          ...submittedPracticeOffer,
          {
            proposed_amount: biddingAmount,
            created_at: moment(),
            username: userDetails.get["user_name"],
            agent_username: null,
            agent_name: null,
            agent_company: null,
          },
        ]);
      } else {
        toast.warning(`Enter ${nextBidAmount} or more`, {
          preventDuplicate: true,
        });
      }
      return;
    }

    if (
      userDetails.get["profiletype"] === "agent" &&
      selectedClient.id === ""
    ) {
      setClientError(
        `You are not approved to submit bids yet. Please see Getting Started in your dashboard to get access from a client or have your own account approved.`,
      );
      return;
    }
    if (selectedClient.property_id) {
      if (selectedClient.property_id === id) {
        toast.info(
          "Selected Client can't place an bid on a propetry posted by them",
        );
        return;
      }
    }
    // if (
    //   // (userDetails.get["profiletype"] === "customer" ||
    //   userDetails.get["profiletype"] === "bidder" &&
    //   myAgent.length <= 0
    // ) {
    //   setError(
    //     "You must be represented by an Agent to submit offers. Go to the My Agent page in your dashboard to connect your account with theirs."
    //   );
    //   return;
    // }
    // if(userDetails.get["profiletype"] === "customer" && )
    if (
      sellerTerms === false ||
      terms === false ||
      timer.isCompleted === true ||
      !biddingAmount
    ) {
      return;
    }

    if (limit !== "unlimited") {
      let newLimit =
        !selectedClient.id || selectedClient.id === "-1"
          ? limit
          : selectedClient.purchase_power
            ? selectedClient.purchase_power
            : limit;

      if (parseFloat(biddingAmount) > newLimit) {
        if (error === "") {
          setError(
            `Your bid is higher than your ${
              selectedClient.id !== "-1" ? "client's" : ""
            } approved Purchasing Power`,
          );
          return;
        }
      }
    }

    if (biddingAmount && biddingAmount >= nextBidAmount) {
      let payload = {
        id: id,
        wsprice: biddingAmount,
        customerid: customerid,
        ruumr_fee: ruumrFeeChecked ? "yes" : "no",
      };

      if (userDetails.get["profiletype"] === "agent") {
        if (selectedClient.id === "-1") {
          payload = {
            ...payload,
            is_agent: false,
            agent_id: userDetails.get["id"],
            userid: userDetails.get["id"],
            email: userDetails.get["email"],
          };
        } else {
          payload = {
            ...payload,
            userid: selectedClient.id,
            email: selectedClient.email,
            is_agent: true,
            agent_id: userDetails.get["id"],
          };
        }
      } else {
        payload = {
          ...payload,
          is_agent: false,
          agent_id: "",
          userid: userDetails.get["id"],
          email: userDetails.get["email"],
        };
      }

      let { status, result, message } = await bidding(payload, site_id);
      if (status === true) {
        // toast.success(message);

        if (
          highestBidder &&
          !!Object.keys(userDetails.get).length &&
          userDetails.get["id"] == highestBidder
        ) {
          toast.success(message, {
            toastId: "bid-success",
          });
        }
        if (result) {
          setBiddingAmount("");
          setError("");
          // setNextBidAmount(result.nextBid);
          // toast.info(result.message);
          // seyProductDetails((prv) => ({
          //   ...prv,
          //   wprice: result.wPrice,
          //   bidcount: result.bidCount,
          // }));
          if (userDetails.get["id"]) handleClose();
        }
      } else {
        if (message.includes("revoked")) {
          toast.error(message, {
            preventDuplicate: true,
          });
        } else {
          toast.warning(message, {
            preventDuplicate: true,
          });
        }
      }
    } else {
      toast.warning(`Enter ${nextBidAmount} or more`, {
        toastId: "nextBid",
      });
    }
  };

  const presentage = useMemo(
    () =>
      biddingAmount
        ? setDecimalPoint((1 / 100) * biddingAmount)
        : // : setDecimalPoint((1 / 100) * nextBidAmount),
          "-",
    [biddingAmount],
  );

  const formatAddress = (getProductDetails) => {
    var tempArr = getProductDetails.loc_address.split(
      getProductDetails.loc_city,
    );
    var tempSubArr2 = tempArr[0].trim().split("");
    if (tempSubArr2[tempSubArr2.length - 1] === ",") {
      tempSubArr2.splice(tempSubArr2.length - 1, 1);
    }
    tempSubArr2.push(" " + getProductDetails.house_number + ", ");
    tempArr[0] = tempSubArr2.join("");
    return tempArr.join(getProductDetails.loc_city);
  };

  const handleClientSelect = (e) => {
    if (e.target.value !== "") {
      let client = clientOptions.filter(
        (client) => client.id == e.target.value,
      );
      if (client.length === 1) {
        setSelectedClient(client[0]);
      }
      setClientError("");
    } else {
      setSelectedClient({ id: "", first_name: "", last_name: "", email: "" });
    }
  };

  return (
    <div className="sc-gbox bidding-form-wrapper">
      <div className="container">
        <div className="row">
          {isMobile ? (
            ""
          ) : (
            <div className="submitBidFormHeader">
              <div
                className="property-sub-head text-left"
                onClick={() =>
                  window.open(
                    `https://www.google.com/maps/search/?api=1&query=${data.lat},${data.lng}`,
                    "_blank",
                  )
                }
                style={{ cursor: "pointer", fontSize: "16px" }}
              >
                {data.house_number
                  ? formatAddress(data)
                  : data.loc_address.split(",").map((item, index) =>
                      index === 0 ? (
                        <>
                          <span>{`${item},`}</span>
                          <br />
                          <span>
                            {`${data.loc_city}${data.loc_city && ","}`}{" "}
                            {`${data.state}${data.state && ","}`}{" "}
                            {`${data.zipcode}`}
                          </span>
                        </>
                      ) : index === data.loc_address.split(",").length - 1 ? (
                        <span>{`${item}`}</span>
                      ) : (
                        <span>{`${item},`}</span>
                      ),
                    )}
              </div>
            </div>
          )}

          <div className="col submitBidFormHeader">
            <div>
              <div className="pvd-tc">
                {Object.keys(timer).length !== 0 && (
                  <>
                    {timer.isCompleted === true ? (
                      <span className="pv-timer">Closed</span>
                    ) : (
                      <>
                        {/* <div className="pl-text">
                          {Object.keys(timer).length !== 0 &&
                            timer.isCompleted === false &&
                            timer.text}
                        </div> */}
                        <div className="pvd-tc no-wrap mt-1">
                          <Timer item={data} />
                        </div>
                      </>
                      // <span className="pv-timer">{`${timer.days}D ${timer.hours}H ${timer.minutes}:${timer.seconds}`}</span>
                    )}
                  </>
                )}
              </div>
              <div
                className="pl-text"
                style={{ textAlign: "center", color: "gray" }}
              >
                {Object.keys(timer).length !== 0 && timer.isCompleted === false
                  ? timer.text
                  : ""}
              </div>
            </div>
          </div>

          <div className="col submitBidFormHeader">
            <div>
              <div className="pvd-tc">
                $
                {data.bidcount
                  ? setDecimalPoint(data.wprice)
                  : setDecimalPoint(data.sprice)}
              </div>
              <div
                className="pl-text"
                style={{ textAlign: "center", color: "gray" }}
              >
                {data.bidcount ? "Current Bid" : "Starting Bid"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={isMobile ? "mobile-bidding-box" : "bidding-box"}>
        {/* {userDetails.get["profiletype"] === "agent" ? (
          <div className="bid-amount">
            <div className="bform-label">Choose Buyer</div>
            <div
              className={
                isMobile ? classes.mobileFormControldiv : classes.formControldiv
              }
            >
              <FormControl
                variant="outlined"
                className={
                  isMobile ? classes.mobileFormControl : classes.formControl
                }
              >
                <Select
                  id="demo-simple-select-outlined"
                  value={selectedClient.id}
                  onChange={handleClientSelect}
                  displayEmpty
                  style={{ height: "50px" }}
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  className={classes.selectEmpty}
                >
                  <MenuItem value="" disabled>
                    Client
                  </MenuItem>
                  {clientOptions.map((client) => (
                    <MenuItem
                      value={client.id}
                    >{`${client.first_name} ${client.last_name}`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        ) : (
          ""
        )} */}

        <div className="bid-amount">
          <div className="bform-label">Enter Bid Amount</div>
          <div className="bid-input-wrap form-group ">
            <span>$</span>
            <input
              className="form-control"
              value={biddingAmount ? setDecimalPoint(biddingAmount) : ""}
              onChange={changeText}
            />
            <div style={{ textAlign: "right", color: "gray" }}>
              {"Min Bid $" +
                setDecimalPoint(
                  data?.enable_start_price && data?.bidcount == 0
                    ? data?.sprice
                    : nextBidAmount,
                )}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="bform-label">Buyer's Premium Amount:</div>
          <div className="bid-input-wrap ml-3">
            <span>{"$" + setDecimalPoint(data.buyers_premium_minimum)}</span>
          </div>
        </div>
        <hr />
        <div className="bfc-bottom bform-label">
          <div>
            <b>Total Amount</b>
          </div>
          <div>
            <b>
              {"$" +
                setDecimalPoint(
                  Number(data.buyers_premium_minimum) +
                    Number(
                      biddingAmount &&
                        setDecimalPoint(
                          biddingAmount ? biddingAmount : nextBidAmount,
                        )
                          .toString()
                          .replace(/,/g, ""),
                    ),
                )}
              {/* // : "-"} */}
            </b>
          </div>
        </div>
        {/* <div className="bfc-bottom bform-label">
          <div className="non-refund-amt">
            <span className="non-refundable-txt">
              Non-Refundable 24 Hour Sold Fee
            </span>
          </div>
          <div>${presentage}</div>
        </div> */}
        {/* {userDetails.get.profiletype !== "agent" && ( */}
        {/* <div className="bid-notify-alert">
            <p>
              Your card will NOT be charged unless your bid is the highest.{" "}
              <br />
              24 Hour Sold will confirm your preferred payment method before
              initiating any payment
               <br />A hold will be placed on your card untill you're outbid. 
            </p>
          </div> 
        {/* )} */}
      </div>

      <p className={isMobile ? "mobile-seller-terms" : ""}>
        {/* Please review the Seller's Terms to make sure that your offer will meet
        the acceptable criteria established by the seller. */}
        Please review the Auction and Property Disclosure to ensure your bid
        will meet the acceptable criteria established by the Seller.
      </p>

      <table className="table table-striped mt-3 mb-4">
        <tbody>
          {data.sellerTerms &&
            Object.keys(data.sellerTerms).map((df) => (
              <tr>
                <td>{df}</td>
                <td>{data.sellerTerms[df]}</td>
              </tr>
            ))}
          {/* <tr>
            <td>Closing date (before)</td>
            <td>{moment(data.date_closed).format("MMMM, DD YYYY")}</td>
          </tr>
          <tr>
            <td>Possession date (after)</td>
            <td>Sepember 6, 2021</td>
          </tr>
          <tr>
            <td>Financing accepted </td>
            <td>{data.financingType}</td>
          </tr>
          <tr>
            <td>Financing</td>
            <td>No Contingency</td>
          </tr>
          <tr>
            <td>Inspection</td>
            <td>No Contingency</td>
          </tr>
          <tr>
            <td>Appraisal Gap</td>
            <td>$10,000 or more</td>
          </tr>
          <tr>
            <td>Aggent Commission</td>
            <td>3%</td>
          </tr>
          <tr>
            <td>Auction End Date</td>
            <td>{moment(data.date_closed).format("MMMM, DD YYYY")}</td>
          </tr> */}
        </tbody>
      </table>
      <div className={isMobile ? "mobile-popup-checkbox" : "bidding-check-box"}>
        {/* <CheckBox
          label={
            <span>
              Request to add the 24 Hour Sold fee into the Purchase Price.{" "}
              <a
                // className='learn-more'
                href="/how-it-works"
                target="_blank"
              >
                Learn more
              </a>
            </span>
          }
          name="purchasePrice"
          checked={ruumrFeeChecked}
          onchange={(e) => setRuumrFeeChecked((prv) => !prv)}
        /> 
        <hr />*/}
        <CheckBox
          // label="I have reviewed the Seller's acceptable terms and confirmed that I am an eligible buyer"
          label="I have reviewed the Auction and Property Disclosures and confirm that I am an eligible buyer"
          name="elgible-buyer"
          checked={sellerTerms}
          onchange={(e) => setSellerTerms((prv) => !prv)}
        />
        <CheckBox
          label={
            <span className="m-0">
              I agree to the{" "}
              <Link button onClick={handleTermsPopup}>
                Terms and Conditions of {global?.storeDetails?.name}.
              </Link>
            </span>
          }
          name="terms"
          checked={terms}
          onchange={(e) => setTerms((prv) => !prv)}
        />
      </div>
      <div className="mt-3 mbb-3">
        <button
          disabled={
            sellerTerms === false ||
            terms === false ||
            timer.isCompleted === true
          }
          className="btn btn-primary bform-pb-btn"
          onClick={doBidding}
        >
          {highestBidder &&
          !!Object.keys(userDetails.get).length &&
          userDetails.get["id"] === highestBidder &&
          timer.isCompleted === false
            ? "You are the Highest Bidder"
            : timer.isCompleted === false
              ? "SUBMIT BID"
              : "Closed"}
        </button>
        <div style={{ textAlign: "center", color: "red" }} className="mt-2">
          {clientError !== "" ? clientError : ""}
        </div>
        {error === "Your bid is higher than your approved Purchasing Power" ? (
          <>
            <p className="text-danger mt-2">
              Your bid is higher than your approved Purchasing Power <br />
              <Link to="/editBidder" className="text-danger">
                <u>Click here to update your bid details.</u>
              </Link>
            </p>
          </>
        ) : (
          <p className="text-danger mt-2">{error}</p>
        )}
      </div>
      {isTermsOpen && <TermsPopup />}
    </div>
  );
};

export default BiddingForm;
