import React, { useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import CheckBox from "../../../components/atoms/CheckBox";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import { showError, showSuccess, showWaring } from "../../../utils";
import axios from "../../../utils/axiosconfig";
import GlobalContext from "../../../context/GlobalContext";
import TermsPopup from "../Popups/TermsPopup";
import { ModalContext } from "../../../context/ModalContext";

const OtpVerification = (props) => {
  const { loading, userLoggedIn, userDetails } = useContext(GlobalContext);
  const { isTermsOpen, handleTermsPopup } = useContext(ModalContext);
  const site_id = global?.storeDetails?.site_id;

  const formik = useFormik({
    initialValues: {
      email: props.formik.values.email || "",
      phone: props.formik.values.phone || "",
      otp: "",
      promotional: 0,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .max(100, "Maximum 100 characters")
        .email("Invalid email format")
        .required("Enter your Email ID"),
      otp: Yup.string()
        .min(6, "Minimum 6 digits")
        .max(6, "Maximum 6 digits")
        .length(6, "Enter a 6 digit Text")
        .required("Text is required"),
      promotional: Yup.number()
        .required("Please accept the terms")
        .oneOf([1, "1"], "Please accept the terms"),
    }),
    onSubmit: async (values) => {
      try {
        loading.set(true);
        const { data: verifyOtp } = await axios.post(
          "verifyPhoneCode",
          {
            email: values.email,
            phone: values.phone,
            verifyToken: true,
            otp: values.otp,
          },
          {
            headers: { site_id },
          },
        );

        if (verifyOtp?.success) {
          loading.set(true);

          // Register API
          const { data: registerData } = await axios.post(
            "sellersignup",
            props.formik.values,
            {
              headers: { site_id },
            },
          );

          if (registerData?.success === "yes") {
            loading.set(false);
            props.nextStep(values, "register_success", registerData);
            // showSuccess(registerData.reason);
            // localStorage.setItem("userToken", registerData.token);
            // userLoggedIn.set(true);
          } else {
            showError(registerData?.reason || "Something went wrong!");
          }
        } else {
          loading.set(false);
          showError(verifyOtp?.message || "Something went wrong!");
        }
      } catch (error) {
        loading.set(false);
        showError(data?.message || "Something went wrong!");
      }
    },
  });

  const handleResendOTP = async () => {
    let { data: sendOtp } = await axios.post(
      "sendPhoneVerifyCode",
      {
        ...props.formik.values,
        sendToken: true,
      },
      {
        headers: { site_id },
      },
    );

    if (sendOtp?.success) {
      showSuccess(sendOtp?.message || "Something went wrong!");
    } else {
      showError(sendOtp?.message || "Something went wrong!");
    }
  };

  return (
    <div className="formContainer">
      <div className="titleSign">Verify Your Mobile Number</div>
      <div className="auth-bottom text-center">
        <img className="phoneLogo" src="/images/phoneIcon.svg" alt="phone" />
      </div>
      <div className="socialAuthCont">
        <p className="text-center">
          We just sent a verification code to{" "}
          <span className="mobn">{props.formik.values.phone}.</span>Enter the
          code below to verify your device.
        </p>
      </div>
      <form className="form m-0">
        <>
          <div className="form-group mt-3">
            <CustomInput
              label="Verification Code"
              name="otp"
              placeholder="Enter Verification Code"
              value={formik.values.otp}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.otp && formik.errors.otp ? (
              <div className="invalid-feedback">{formik.errors.otp}</div>
            ) : null}
          </div>
        </>
        <div className="form-group mt-3 check-label">
          <CheckBox
            label={
              <span>
                I agree to receive promotional messages from My Next Bid.
                Messages and Data rates may apply. Messages frequency varies.
                View{" "}
                {/* <a href={global?.storeDetails?.terms_link} target="_blank"> */}
                <Link button to="" onClick={handleTermsPopup}>
                  Terms of Use
                </Link>{" "}
                and <Link to="/privacy_policies">Privacy Policy</Link>
                {/* , and{" "} */}
                {/* <Link button to="" onClick={handleTermsPopup}>
                  Participation Terms
                </Link> */}
                .{/* </a> */}
              </span>
            }
            onchange={(e) => {
              formik.setFieldValue(
                "promotional",
                formik.values.promotional === 1 ? 0 : 1,
              );
            }}
            onBlur={formik.handleBlur}
            name="promotional"
            value="1"
            checked={
              formik.values.promotional === 1 ||
              formik.values.promotional === "1"
            }
          />
          {formik.errors.promotional && formik.touched.promotional ? (
            <p className="invalid-feedback">Please accept the terms</p>
          ) : null}
        </div>
        <div className="secBtn mt-2">
          <PrimaryButton
            label={"SUBMIT"}
            onClick={formik.handleSubmit}
            disabled={loading.get}
          />
        </div>
        <div className="auth-bottom mt-4 mb-0 text-center">
          <a
            className="blue-link changeColor"
            style={{ cursor: "pointer" }}
            onClick={handleResendOTP}
          >
            Resend my code
          </a>
        </div>
      </form>
      {isTermsOpen && <TermsPopup />}
    </div>
  );
};

export default OtpVerification;
