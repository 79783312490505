import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../../context/GlobalContext";
import { getTimeInterval } from "../../../utils";
import socket from "../../../utils/socket";
import moment from "moment";

let serverTime = new Date();
if (socket) {
  socket.on("sliservertime", (data) => {
    if (moment(data.dTime).isValid()) {
      serverTime = new Date(data.dTime);
    }
  });
}

const Timer = (props) => {
  const { currentTime } = useContext(GlobalContext);
  const [timer, setTimer] = useState({});
  const [buyTimer, setBuyTimer] = useState({});
  const [offerTimer, setOfferTimer] = useState({});
  const [item, setItem] = useState({});

  useEffect(() => {
    if (props?.item?.id) setItem(props.item);
  }, [props?.item?.id]);

  useEffect(() => {
    if (item.id) {
      if (item?.auction == 1 && serverTime) {
        const interval = setInterval(() => {
          setTimer(
            getTimeInterval(
              item["date_added"],
              item["date_closed"],
              serverTime,
              item["market_status"],
              "Auction",
              item["id"],
            ),
          );
        }, 1000);
        return () => {
          clearInterval(interval);
        };
      }
    }
  }, [item?.id]);

  useEffect(() => {
    if (item.id) {
      if (item?.offer == 1 && serverTime) {
        const interval = setInterval(() => {
          setOfferTimer(
            getTimeInterval(
              item["o_date_added"],
              item["o_date_closed"],
              serverTime,
              item["market_status"],
              "Offer",
              item["id"],
            ),
          );
        }, 1000);
        return () => {
          clearInterval(interval);
        };
      }
    }
  }, [item?.id]);

  useEffect(() => {
    if (item.id) {
      if (item?.buynow == 1 && serverTime) {
        const interval = setInterval(() => {
          setBuyTimer(
            getTimeInterval(
              item["b_date_added"],
              item["b_date_closed"],
              serverTime,
              item["market_status"],
              "Buy Now",
              item["id"],
            ),
          );
        }, 1000);
        return () => {
          clearInterval(interval);
        };
      }
    }
  }, [item?.id]);

  const formatTime = (timeObj) => {
    const { days, hours, minutes, seconds } = timeObj;

    if (hours < 24 && days < 1) {
      return `${hours}H : ${minutes}M : ${seconds}S`;
    } else {
      return `${days}D : ${hours}H : ${minutes}M`;
    }
  };

  return item?.id ? (
    <>
      {props.showClock ? (
        <>
          {!timer?.isCompleted ? (
            <i className="far fa-clock"></i>
          ) : !buyTimer?.isCompleted ? (
            <i className="far fa-clock"></i>
          ) : !offerTimer?.isCompleted ? (
            <i className="far fa-clock"></i>
          ) : (
            ""
          )}{" "}
        </>
      ) : null}

      {item.auction == 1 && Object.keys(timer).length ? (
        <>
          {Object.keys(timer).length && timer.isCompleted ? (
            <>
              {Object.keys(buyTimer).length &&
              !buyTimer.isCompleted &&
              item.buynow == 1 ? (
                <>
                  {buyTimer.text == "Bidding Starts In" ? (
                    <>
                      {/* <div className="pl-text mb-2">{buyTimer.status}</div> */}
                      {moment(item?.b_date_added).format("MM/DD/YY") +
                        " at " +
                        moment(item?.b_date_added).format("LT")}
                    </>
                  ) : (
                    `${props.showText ? buyTimer.status : ""} ${formatTime(
                      buyTimer,
                    )}`
                  )}
                </>
              ) : Object.keys(offerTimer).length &&
                !offerTimer.isCompleted &&
                item.offer == 1 ? (
                <>
                  {offerTimer.text == "Bidding Starts In" ? (
                    <>
                      {/* <div className="pl-text mb-2">{offerTimer.status}</div> */}
                      {moment(item?.o_date_added).format("MM/DD/YY") +
                        " at " +
                        moment(item?.o_date_added).format("LT")}
                    </>
                  ) : (
                    `${props.showText ? offerTimer.status : ""} ${formatTime(
                      offerTimer,
                    )}`
                  )}
                </>
              ) : (
                <>
                  <span className="text-capitalize">
                    {item.market_status == "open"
                      ? "Ended"
                      : item.listing_status == "expired"
                        ? "Listing Closed"
                        : item.listing_status == "under_contract"
                          ? "Under Contract"
                          : item.listing_status}
                  </span>
                </>
              )}
            </>
          ) : timer.text == "Bidding Starts In" ? (
            <>
              {/* <div className="pl-text  mb-2">{timer.status}</div> */}
              {moment(item?.start_date).format("MM/DD/YY") +
                " at " +
                moment(item?.start_date).format("LT")}
            </>
          ) : (
            `${props.showText ? timer.status : ""} ${formatTime(timer)}`
          )}
        </>
      ) : item.buynow == 1 &&
        !buyTimer.isCompleted &&
        Object.keys(buyTimer).length ? (
        <>
          {!buyTimer.isCompleted ? (
            <>
              {buyTimer.text == "Bidding Starts In" ? (
                <>
                  {buyTimer.status +
                    " " +
                    moment(item?.b_date_added).format("MM/DD/YY") +
                    " at " +
                    moment(item?.b_date_added).format("LT")}
                </>
              ) : (
                `${props.showText ? buyTimer.status : ""} ${formatTime(
                  buyTimer,
                )}`
              )}
            </>
          ) : (
            <>
              <span className="text-capitalize">
                {item.market_status == "open"
                  ? "Ended"
                  : item.listing_status == "expired"
                    ? "Listing Closed"
                    : item.listing_status == "under_contract"
                      ? "Under Contract"
                      : item.listing_status}
              </span>
            </>
          )}
        </>
      ) : item.offer == 1 &&
        !offerTimer.isCompleted &&
        Object.keys(offerTimer).length ? (
        <>
          {!offerTimer.isCompleted ? (
            <>
              {offerTimer.text == "Bidding Starts In" ? (
                <>
                  {offerTimer.status +
                    " " +
                    moment(item?.o_date_added).format("MM/DD/YY") +
                    " at " +
                    moment(item?.o_date_added).format("LT")}
                </>
              ) : (
                `${props.showText ? offerTimer.status : ""} ${formatTime(
                  offerTimer,
                )}`
              )}
            </>
          ) : (
            <>
              <span className="text-capitalize">
                {item.market_status == "open"
                  ? "Ended"
                  : item.listing_status == "expired"
                    ? "Listing Closed"
                    : item.listing_status == "under_contract"
                      ? "Under Contract"
                      : item.listing_status}
              </span>
            </>
          )}
        </>
      ) : (
        <>
          {item.market_status == "sold" && (
            <span className="text-capitalize">Product Sold</span>
          )}
        </>
      )}
    </>
  ) : null;
};

export default Timer;
