import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import BuyerContactForm from "./BuyerContactForm";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import SearchCard from "../../../components/atoms/SearchCard";
import { getSearch } from "../../../API/search";
import SearchGrid from "../HomePage/SearchGrid";
import "./StaticSection.css";
import { useSearch } from "../../../context/SearchContext";
const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box className="mt-4">{children}</Box>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `view-account-${index}`,
    "aria-controls": `view-account-tabpanel-${index}`,
  };
}
const site_id = global?.storeDetails?.site_id;

const StaticSection = (props) => {
  const [value, setValue] = useState(0);
  const [viewUpcomingProperties, setViewUpcomingProperties] = useState([]);
  const [isUpcomingFetched, setIsUpcomingFetched] = useState(false);
  const { searchQuery } = useSearch();

  const [filteredProperties, setFilteredProperties] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (window.location.search) {
      let query = qs.parse(window.location.search);
      setPage(Number(query.page) || 1);
      setBody({
        page: query.page || 1,
        limit: 6,
        // cities: query.cities ? query.cities.split(",") : "",
        title: query.title || "",
        ids: query.ids ? query.ids.split(",") : "",
        sortby: query.sortby || "1",
        maxPrice: query.maxPrice || "",
        minPrice: query.minPrice || "",
        status: query.status
          ? query.status.split(",")
          : ["coming_soon", "open"],
        listing_type: query.listing_type ? query.listing_type.split(",") : "",
        occupency_type: query.occupency_type
          ? query.occupency_type.split(",")
          : "",
        minbeds: query.minBed || "",
        maxSqft: query.maxSqft || "",
        minSqft: query.minSqft || "",
        minLotSize: query.minLotSize || "",
        maxLotSize: query.maxLotSize || "",
        minYearbuilt: query.minYearbuilt || "",
        maxYearbuilt: query.maxYearbuilt || "",
        parking: query.parking || "",
        exact_bathroom: query.exact_bathroom ? query.exact_bathroom : false,
        exact_bedroom: query.exact_bedroom ? query.exact_bedroom : false,
        maxbeds: query.maxBed || "",
        garage: query.garage == "yes" ? "yes" : "no",
        is_open_house: query.is_open_house == "yes" ? "yes" : "no",
        is_3D_Tour: query.is_3D_Tour == "yes" ? "yes" : "no",
        keywords: query.keywords ? query.keywords : "",
        minbathroom: query.minBath || "",
        bedroom: query.bedroom || "",
        bathroom: query.bathroom || "",
        maxbathroom: query.maxBath || "",
        property_type: query.property_type
          ? query.property_type.split(",")
          : "",
        minSize: query.minSize ? query.minSize.toString() : "",
        maxSize: query.maxSize ? query.maxSize.toString() : "",
        type: query.type ? query.type.split(",") : "",
        rPrice: query.rPrice,

        bound:
          query.north && query.south && query.east && query.west
            ? {
                north: query.north,
                south: query.south,
                east: query.east,
                west: query.west,
              }
            : {},
        initial: true,
      });
    }
  }, [window.location.search]);
  const site_id = localStorage.getItem("site_id");
  const [body, setBody] = useState(undefined);

  const getAllProperties = async (action, setProperties, setFetched) => {
    let property = { ...body, action };

    let data = await getSearch(property, site_id);

    if (data.status === true) {
      let filteredProperties = [];

      if (action === "feature") {
        filteredProperties = data.result["productdetails"].filter(
          (property) => property.featured_auction_property === 1,
        );
      } else if (action === "upcoming") {
        const current = new Date().toISOString();
        filteredProperties = data.result["productdetails"].filter(
          (property) => new Date(property.date_added) >= new Date(current),
        );
      } else {
        filteredProperties = data.result["productdetails"];
      }

      setProperties([...filteredProperties]);
      setFetched(true);
    }
  };

  // useEffect(() => {
  //   const fetchProperties = async () => {
  //     const body = {
  //       page: 1,
  //       limit: 6,
  //       searchbar: searchQuery,
  //     };

  //     try {
  //       const data = await getSearch(body, site_id);
  //       if (data.status === true) {
  //         const filteredProperties = data.result["productdetails"].filter(
  //           (property) => property.listing_status === "for_sale"
  //         );
  //         setViewProperties([...filteredProperties]);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching properties", error);
  //     }
  //   };

  //   fetchProperties();
  // }, [searchQuery, site_id]);

  // useEffect(() => {
  //   if (site_id != (null || undefined)) {
  //     getAllProperties();
  //   }
  // }, [site_id]);
  useEffect(() => {
    if (!isUpcomingFetched) {
      getAllProperties(
        "active",
        setViewUpcomingProperties,
        setIsUpcomingFetched,
      );
    }
  }, [isUpcomingFetched]);
  // useEffect(() => {
  //
  //   if (searchQuery) {
  //     // const filtered = viewProperties.filter((property) => {
  //     //   return (
  //     //     property?.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //     //     property?.city?.toLowerCase().includes(searchQuery.toLowerCase())
  //     //   );
  //     // });
  //     setFilteredProperties(viewProperties);

  //   } else {
  //     setFilteredProperties(filtered);
  //   }
  // }, [viewProperties, searchQuery]);

  return (
    <>
      <section className="upcomingAuctions">
        <div className="titleSec">
          <h4>Our Auctions</h4>
          <h2>Active Auctions</h2>
        </div>
        <div className="card-slider" style={{ margin: "10px 0px" }}>
          {viewUpcomingProperties.length > 0 ? (
            <SearchGrid properties={viewUpcomingProperties} />
          ) : (
            <p
              style={{
                textAlign: "center",
                margin: "20px 0",
                fontSize: "18px",
                color: "#555",
              }}
            >
              No Properties Found
            </p>
          )}
        </div>
      </section>
      <section className="howItWorks">
        <div className="titleSec">
          <h4>How It Works</h4>
          <h2>Getting Started Is Easy</h2>
        </div>
        <div className="container">
          <div className="howWork mb-4">
            <div className="partOne">
              <div className="card">
                <div>
                  <span>1</span>
                  <h3>Sign Up :</h3>
                  <p>Create your free account.</p>
                </div>
                <div className="d-flex justify-content-end">
                  <img src="images/howOne.svg" />
                </div>
              </div>
              <div className="card">
                <div>
                  <span>2</span>
                  <h3>Browse Auctions :</h3>
                  <p>Explore live and upcoming auctions.</p>
                </div>
                <div className="d-flex justify-content-end">
                  <img src="images/howTwo.svg" />
                </div>
              </div>
            </div>
            <div className="partTwo">
              <div className="card">
                <div>
                  <span>3</span>
                  <h3>Place Your Bids :</h3>
                  <p>Participate in real-time bidding.</p>
                </div>
                <div className="d-flex justify-content-end">
                  <img src="images/howThree.svg" />
                </div>
              </div>
              <div className="card">
                <div>
                  <span>4</span>
                  <h3>Win & Close :</h3>
                  <p>Secure your next investment property.</p>
                </div>
                <div className="d-flex justify-content-end">
                  <img src="images/howFour.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StaticSection;
