import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import GlobalContext from "../../../context/GlobalContext";
import "./dashboard.css";
import axios from "../../../utils/axiosconfig";
import { ModalContext } from "../../../context/ModalContext";

const MenuLinks = (props) => {
  const { userDetails, verificationStatus, agentVerification } =
    useContext(GlobalContext);
  const location = useLocation();
  const history = useHistory();
  const [verificationCount, setVerificationCount] = useState(0);
  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");

  const { isRegisterToBidOpen, setRegisterToBidOpen } =
    useContext(ModalContext);

  const calculateVerificationCount = () => {
    let count = 0;
    let notificationStatusPayload =
      userDetails.get["profiletype"] === "agent"
        ? agentVerification.get
        : userDetails.get["profiletype"] === "customer" ||
            userDetails.get["profiletype"] === "builder" ||
            userDetails.get["profiletype"] === "bidder"
          ? verificationStatus.get
          : "";
    if (notificationStatusPayload) {
      for (const property in notificationStatusPayload) {
        if (!notificationStatusPayload[property]) {
          count += 1;
        }
      }
    }
    setVerificationCount(count);
  };

  useEffect(() => {
    if (location.pathname.includes("/myclients")) {
      history.push(`/agentverification`);
    }
  }, [location]);

  useEffect(() => {
    if (userDetails && userDetails.get) {
      calculateVerificationCount();
    }
  }, [verificationStatus.get, agentVerification.get, userDetails.get]);

  return (
    <div className="cwhite-box dbw-box">
      {/* <h5>Private</h5> */}
      <ul className="db-menu">
        <li className="d-none">
          <NavLink to={`/getting-started`} activeClassName="active">
            Getting Started
          </NavLink>
        </li>
        <>
          {userDetails.get["profiletype"] === "bidder" ||
          userDetails.get["profiletype"] === "customer" ||
          userDetails.get["profiletype"] === "builder" ? (
            <>
              {" "}
              <li className="buyer-verification-link d-none">
                <NavLink to={`/buyerverification`} activeClassName="active">
                  Buyer Verification
                  {verificationCount ? <span>{verificationCount}</span> : ""}
                </NavLink>
              </li>
            </>
          ) : (
            ""
          )}
          {userDetails.get["profiletype"] === "agent" ? (
            <>
              <li className="buyer-verification-link d-none">
                <NavLink to={`/agentverification`} activeClassName="active">
                  Client and Verification
                  {verificationCount ? <span>{verificationCount}</span> : ""}
                </NavLink>
              </li>
            </>
          ) : (
            ""
          )}
          {userDetails.get["id"] ? (
            <>
              {/* <li>
                <NavLink to={`/watchlist`} activeClassName="active">
                  Following
                </NavLink>
              </li>
              <li className="d-none">
                <NavLink to={`/my-bids`} activeClassName="active">
                  My Bids (Active Properties)
                </NavLink>
              </li>
              <li className="d-none">
                <NavLink to={`/upcoming`} activeClassName="active">
                  Upcoming Showings
                </NavLink>
              </li>
              <li className="d-none">
                <NavLink to={`/auctions-won`} activeClassName="active">
                  Properties Won
                </NavLink>
              </li>
              <li>
                <NavLink to={`/my_account`} activeClassName="active">
                  My Account
                </NavLink>
              </li>

              {userDetails.get["bid_approval"] != "true" ? (
                <li>
                  <NavLink
                    to={`/`}
                    activeClassName=""
                    onClick={() => {
                      setRegisterToBidOpen(true);
                    }}
                  >
                    Register To Bid
                  </NavLink>
                </li>
              ) : null} */}
              <li>
                <NavLink to={`/profile`} activeClassName="active">
                  My Profile
                </NavLink>
              </li>
              <li>
                <NavLink to={`/my_auction`} activeClassName="active">
                  My Auctions
                </NavLink>
              </li>
              <li>
                <NavLink to={`/saved_search`} activeClassName="active">
                  Saved Searches
                </NavLink>
              </li>
              <li>
                <NavLink to={`/product_history`} activeClassName="active">
                  History
                </NavLink>
              </li>
              <li>
                <NavLink to={`/watchlist`} activeClassName="active">
                  Watchlist
                </NavLink>
              </li>
              {/* <li>
                <NavLink to={`/purchage_profile`} activeClassName="active">
                  Purchase Profile
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink to={`/cards`} activeClassName="active">
                  My Cards
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink to={`/my-listings`} activeClassName="active">
                  Selling
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink to={`/my-buyers`} activeClassName="active">
                  Buyers
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink to={`/sell`} activeClassName="active">
                  Add Listing
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink to={`/my_account`} activeClassName="active">
                  My Account
                </NavLink>
              </li> */}
            </>
          ) : null}
          {/* <li>
            <NavLink to={`listing_after`} activeClassName="active">
              Selling After
            </NavLink>
          </li> */}
        </>
      </ul>

      {userDetails.get["id"] ? (
        <>
          {/* <h5>Public</h5> */}
          <ul className="db-menu">
            {/* <li>
              <NavLink to={`/profile`} activeClassName="active">
                My Profile
              </NavLink>
            </li> */}
            <li>
              {/* <NavLink to={`/comments`} activeClassName="active">
                My Questions & Comments
              </NavLink> */}
            </li>
            <li className="d-none">
              <NavLink to={`/listings`} activeClassName="active">
                My Listings
              </NavLink>
            </li>
            {userDetails.get.role === "1" && (
              <li className="d-none">
                <NavLink to={`/bids-closed`} activeClassName="active">
                  My Bids (Closed Properties)
                </NavLink>
              </li>
            )}
          </ul>
        </>
      ) : null}

      <h5 className="d-md-none">Resources</h5>
      <ul className="db-menu db-menu-mobile d-md-none">
        <li>
          <a href="/faq">FAQ's</a>
        </li>
        <li>
          <a href="/contact_us">Contact Us</a>
        </li>
      </ul>
    </div>
  );
};

export default MenuLinks;
