import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../../components/molecules/Layout";
import "./Contactus.css";
import PrimaryButton from "../../atoms/PrimaryButton";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { saveContact } from "../../../store/slices/contact";
import * as Yup from "yup";
import { toast } from "react-toastify";
import GlobalContext from "../../../context/GlobalContext";
import {
  normalizePhoneNumberInput,
  phoneNumberValid,
} from "../../../utils/FormikUtils";

const Contactus = () => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.contact?.message);
  const { userDetails } = useContext(GlobalContext);
  const history = useHistory();
  const validationArray = Yup.object({
    first_name: Yup.string()
      .trim()
      .matches(/^[a-zA-Z ]*$/g, "Special characters not allowed")
      .max(15, "Maximum 15 characters")
      .required("Required"),

    email: Yup.string().email("Invalid email format").required("Required"),
    phone_number: Yup.string()
      .required("Phone number is required")
      .test("checkPhone", "Enter valid mobile phone", phoneNumberValid),
    property: Yup.string()
      .trim()
      .min(10, "Minimum 10 characters")
      .max(100, "Maximum 100 characters")
      .required("Required"),
    messages: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      property: "",
      messages: "",
    },
    validationSchema: validationArray,
    onSubmit: async (values) => {
      dispatch(saveContact(values)).then((response) => {
        if (response?.payload.status === "success") {
          toast.success("Thank you! Your message has been successfully sent", {
            preventDuplicate: true,
          });
          history.push("/");
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });
  const contactInfo = [
    {
      id: "first_name",
      label: "First Name",
      name: "first_name",
      type: "text",
      placeholder: "",
      class: "col-sm-6 col-12 form-group text-left",
      autoFocus: true,
      formik: formik,
    },
    {
      id: "last_name",
      label: "Last Name",
      name: "last_name",
      type: "text",
      placeholder: "",
      class: "col-sm-6 col-12 form-group text-left",
      formik: formik,
    },
    {
      id: "email",
      label: "Email Address",
      name: "email",
      type: "email",
      placeholder: "e.g. jacksmith@gmail.com",
      class: "col-sm-6 col-12 form-group text-left",
      formik: formik,
    },
    {
      id: "phone_number",
      label: "Phone Number",
      name: "phone_number",
      type: "text",
      placeholder: "(999) 999-999",
      class: "col-sm-6 col-12 form-group text-left",
      formik: formik,
      enableLongNumbers: 1,
    },
    {
      id: "property",
      label: "Property Address",
      name: "property",
      type: "text",
      placeholder: "Enter Property Address",
      class: "col-12 form-group text-left",
      formik: formik,
      enableLongNumbers: 1,
    },
    {
      id: "messages",
      label: "Question/Message",
      name: "messages",
      type: "textarea",
      placeholder: "Enter Message",
      class: "col-12 form-group text-left",
      formik: formik,
    },
  ];

  useEffect(() => {
    if (userDetails.get) {
      formik.setFieldValue("first_name", userDetails.get.first_name || "");
      formik.setFieldValue("last_name", userDetails.get.last_name || "");
      formik.setFieldValue("email", userDetails.get.email || "");
      formik.setFieldValue("phone_number", userDetails.get.phone || "");
    }
  }, [userDetails.get]);

  useEffect(() => {
    formik.setFieldValue(
      "phone_number",
      normalizePhoneNumberInput(formik.values.phone_number),
    );
  }, [formik?.values.phone_number]);

  return (
    <Layout>
      <div className="contact-main-container">
        <div className="contact-banner">
          <img src="/images/contact_us.png" alt="" />
        </div>
        <div className="container cu-margin-125">
          <div className="row">
            <div className="col-xs-12 col-md-8 padtop">
              <div className="contactform">
                <div className="cu-head">Contact us</div>
                <div className="text-center scontact-us-wrap flex-btnscol">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      {contactInfo.map((field) => (
                        <div className={field.class} key={field.id}>
                          <label htmlFor={field.id}>{field.label}</label>
                          {field.type === "textarea" ? (
                            <textarea
                              id={field.id}
                              className="form-control"
                              placeholder={field.placeholder}
                              {...formik.getFieldProps(field.id)}
                            />
                          ) : (
                            <input
                              id={field.id}
                              type={field.type}
                              className="form-control"
                              placeholder={field.placeholder}
                              {...formik.getFieldProps(field.id)}
                            />
                          )}
                          {formik.touched[field.id] &&
                            formik.errors[field.id] && (
                              <div className="error">
                                {formik.errors[field.id]}
                              </div>
                            )}
                        </div>
                      ))}
                      <div className="col-12 mt-3">
                        <PrimaryButton label="Submit" type="submit" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-4 padtop">
              <div className="contactform cfRt">
                <div className="cu-head">Reach Us</div>
                <div className="media">
                  <div className="media-left">
                    <span className="material-icons">location_on</span>
                  </div>
                  <div className="media-body">
                    <h5 className="ru-head">Hours of Operation</h5>
                    <span className="ru-con">8 AM to 6 PM CST (Mon - Fri)</span>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left">
                    <span className="material-icons">mail</span>
                  </div>
                  <div className="media-body">
                    <h5 className="ru-head">Email Address</h5>
                    <a className="ru-con">support@mynextbid.com</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contactus;
