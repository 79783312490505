import React, { useEffect, useContext } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useHistory } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
const Layout = (props) => {
  const history = useHistory();
  const {
    userLoggedIn,
    userDetails,
    userLocation,
    headerLocation,
    mapRadius,
    storeDetails,
  } = useContext(GlobalContext);
  const store = { store: global?.storeDetails };

  return (
    <>
      <Header store_data={store} />
      <div
        className={`${
          history.location.pathname === "/"
            ? "home-container"
            : history.location.pathname === "/search"
              ? "main-container changeContainer"
              : "main-container"
        } ${userDetails.get["status"] == "incomplete" ? "inCplWrpr" : ""}`}
      >
        {props.children}
      </div>
      {/* {history.location.pathname === "/search" ? null : ( */}
      <Footer store_data={store} />
      {/* )} */}
    </>
  );
};

export default Layout;
