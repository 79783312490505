import React, { useState, useContext, useRef, useEffect } from "react";
import moment from "moment";
import RuumrModal from "../Modal";
import BiddingForm from "./BiddingForm";
import GlobalContext from "../../../context/GlobalContext";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { setDecimalPoint } from "../../../utils";
import Timer from "../Timer/timer";
import PrimaryButton from "../../atoms/PrimaryButton";

const useStyles = makeStyles((theme) => ({
  customFontSize: {
    fontSize: "13px",
  },
}));

const PropertyBids = ({
  data,
  timer,
  nextBidAmount,
  setNextBidAmount,
  rtbCompleted,
  seyProductDetails,
  bidContainer,
  width,
  isMobile,
  myClientsList,
  myAgent,
  from,
  setSubmittedPracticeOffer,
  submittedPracticeOffer,
  setModelopen,
  modalopen,
  submittingBid,
  highestBidder,
  hasRegistered,
}) => {
  // const [modalopen, setModelopen] = useState(false);

  const { userLoggedIn, userDetails } = useContext(GlobalContext);

  const classes = useStyles();

  const mobileWidth = useMediaQuery("(max-width:766px)");

  // useEffect(() => {
  //   const scrollTo = window.location.href.slice(
  //     window.location.href.indexOf("#")
  //   );
  //   scrollTo === "#bids" &&
  //     bidContainer.current.scrollIntoView({ behavior: "smooth" });
  // }, [window.location.href]);

  const toggleModal = () => {
    setModelopen(!modalopen);
  };
  return (
    <div className="bids-box-wrapper mb-5" ref={bidContainer}>
      {from === "practice" ? (
        ""
      ) : (
        <div className="bo-property-head">Online Auction</div>
      )}

      <div className="bids-box">
        <div className="bids-content">
          {!mobileWidth && (
            <>
              <div>
                <div className="bc-head">
                  $
                  {data.bidcount
                    ? setDecimalPoint(data.wprice)
                    : setDecimalPoint(data.sprice)}
                </div>
                <div className="currBid mt-1">
                  {data.bidcount ? "Current Bid" : "Starting Bid"}
                </div>
              </div>
            </>
          )}
          {mobileWidth && from === "practice" ? (
            <>
              <div>
                <div className="bc-head">
                  $
                  {data.bidcount
                    ? setDecimalPoint(data.wprice)
                    : setDecimalPoint(data.sprice)}
                </div>
                <div className="currBid mt-1">
                  {data.bidcount ? "Current Bid" : "Starting Bid"}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {/* {from === "practice" && mobileWidth ? (
            ""
          ) : (
            <div>
              <div className="bc-head">{data.bidcount}</div>
              <div className="bc-text">Bids</div>
            </div>
          )} */}

          {/* {mobileWidth && from !== "practice" ? (
            <div>
              <div className="bc-head">{data.views || 0}</div>
              <div className="bc-text">Views</div>
            </div>
          ) : (
            ""
          )} */}
          {/* <div>
            <div className="bc-head no-wrap">
              {moment(data.date_closed).format("MM/DD/YY") +
                " at " +
                moment(data.date_closed).format("LT")}
            </div>
            <div className="bc-text">Ending on</div>
          </div> */}
        </div>
        <div className="bidSts">
          {(data.market_status === "open" || "sold") &&
          data.wprice != 1 &&
          data.rprice != 0 &&
          data.admin_approved == 1 &&
          data.show_min_price_indicator === "true" &&
          data.bidcount > 0 ? (
            <>
              {data.wprice >= data.rprice
                ? `Minimum Sale Price Achieved`
                : `Minimum Sale Price Not Met`}
            </>
          ) : null}
        </div>

        {/* {from === "practice" ? (
          ""
        ) : (
          <p className="property-head text-center my-2">
            <Tooltip
              title="The home will be accepting bids in real-time, online on the Live Bidding date. Before you can submit a bid, you must register to bid. The timer resets to 3 minutes for each bid received after the auction end time. When bidding closes and the seller's minimum has been reached, the highest bidder will be contacted by the seller to complete the transaction."
              classes={{ tooltip: classes.customFontSize }}
              arrow
              placement="top"
            >
              <span className="how-comments-work">How Bidding Works</span>
            </Tooltip>
          </p>
        )} */}
      </div>
      {timer &&
      Object.keys(timer)?.length != 0 &&
      data.auction == 1 &&
      data.market_status != "sold" &&
      timer?.isCompleted != true ? (
        <div>
          <span className="headTil">
            <PrimaryButton
              onClick={() => submittingBid()}
              btnSize="small"
              label={
                <>
                  {userLoggedIn.get ? (
                    userDetails.get["id"] == data.user_id ? (
                      "Can't bid on own property"
                    ) : timer.isCompleted === false &&
                      timer.text === "Auction Ends In" &&
                      rtbCompleted &&
                      timer.text !== "Bidding Starts In" &&
                      timer.isCompleted === false &&
                      highestBidder &&
                      !!Object.keys(userDetails.get).length &&
                      userDetails.get["id"] == highestBidder ? (
                      "You are the Highest Bidder"
                    ) : timer.isCompleted === false &&
                      timer.text === "Auction Ends In" &&
                      rtbCompleted &&
                      timer.text !== "Bidding Starts In" &&
                      timer.isCompleted === false ? (
                      "Submit a Bid"
                    ) : (timer.isCompleted === false &&
                        timer.text === "Auction Ends In" &&
                        !rtbCompleted) ||
                      (timer.isCompleted === false &&
                        timer.text === "Bidding Starts In" &&
                        !rtbCompleted) ? (
                      <>Auction Registration</>
                    ) : timer.isCompleted === false &&
                      timer.text === "Bidding Starts In" &&
                      rtbCompleted ? (
                      "Accepting Bids Soon"
                    ) : (
                      "Biddingclosed"
                    )
                  ) : !userLoggedIn.get && timer.isCompleted === false ? (
                    <>Auction Registration</>
                  ) : !userLoggedIn.get && timer.isCompleted ? (
                    "Bidding Closed"
                  ) : null}
                </>
              }
            />
          </span>
        </div>
      ) : null}
      {/* <button className="btn btn-primary w-100" onClick={submittingBid}>
        {userLoggedIn.get
          ? userDetails.get["id"] == data.user_id
            ? "Can't bid on own property"
            : timer.isCompleted === false &&
              timer.text === "Auction Ends In" &&
              highestBidder &&
              !!Object.keys(userDetails.get).length &&
              userDetails.get["id"] == highestBidder
            ? "You are High Bidder"
            : timer.isCompleted === false &&
              timer.text === "Auction Ends In" &&
              timer.text === "Bidding Starts In" &&
              !rtbCompleted
            ? "Register for Auction"
            : timer.isCompleted === true
            ? "Bidding has closed"
            : "ACCEPTING BIDS SOON"
          : "Register for Auction"}
      </button> */}
      <div className="bids-box timerBidBox">
        <div className="bids-content">
          {!mobileWidth && (
            <>
              <div>
                <div className="pl-text">
                  {Object.keys(timer).length !== 0 &&
                    timer.isCompleted === false &&
                    timer.text === "Bidding Starts In" &&
                    "Bidding Starts"}
                </div>
                <div className="pvd-tc no-wrap mt-1">
                  <Timer item={data} />
                  {/* {Object.keys(timer).length !== 0 && (
                    <>
                      {timer.isCompleted === true ? (
                        <span className="pv-timer" style={{ color: "#000" }}>
                          Closed
                        </span>
                      ) : timer.text === "Bidding Starts In" ? (
                        <span className="bc-head" style={{ color: "#000" }}>
                          {moment(data.date_added).format("MM/DD/YY") +
                            " at " +
                            moment(data.date_added).format("LT")}
                        </span>
                      ) : (
                        <span className="pv-timer">{`${timer.days}D ${timer.hours}H ${timer.minutes}:${timer.seconds}`}</span>
                      )}
                    </>
                  )} */}
                </div>
              </div>
            </>
          )}
          {mobileWidth && (
            <>
              <div>
                <div className="pl-text">
                  {Object.keys(timer).length !== 0 &&
                    timer.isCompleted === false &&
                    timer.text === "Bidding Starts In" &&
                    "Bidding Starts"}
                </div>
                <div className="pvd-tc no-wrap mt-1">
                  <Timer item={data} />
                  {/* {Object.keys(timer).length !== 0 && (
                    <>
                      {timer.isCompleted === true ? (
                        <span className="pv-timer" style={{ color: "#000" }}>
                          Closed
                        </span>
                      ) : timer.text === "Bidding Starts In" ? (
                        <span className="bc-head" style={{ color: "#000" }}>
                          {moment(data.date_added).format("MM/DD/YY") +
                            " at " +
                            moment(data.date_added).format("LT")}
                        </span>
                      ) : (
                        <span className="pv-timer">{`${timer.days}D ${timer.hours}H ${timer.minutes}:${timer.seconds}`}</span>
                      )}
                    </>
                  )} */}
                </div>
              </div>
            </>
          )}
          {mobileWidth && from === "practice" ? (
            <>
              <div>
                <div className="pvd-tc">
                  {Object.keys(timer).length !== 0 && (
                    <>
                      {timer.isCompleted === true ? (
                        <span className="pv-timer" style={{ color: "#000" }}>
                          Closed
                        </span>
                      ) : timer.text === "Bidding Starts In" ? (
                        <span className="pv-timer" style={{ color: "#000" }}>
                          {moment(data.date_added).format("MM/DD/YY") +
                            " at " +
                            moment(data.date_added).format("LT")}
                        </span>
                      ) : (
                        <span className="pv-timer">{`${timer.days}D ${timer.hours}H ${timer.minutes}:${timer.seconds}`}</span>
                      )}
                    </>
                  )}
                </div>
                <div className="pl-text">
                  {Object.keys(timer).length !== 0 &&
                    timer.isCompleted === false &&
                    timer.text}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <RuumrModal
        open={modalopen}
        handleClose={toggleModal}
        modalClass="biddingModal"
      >
        <BiddingForm
          data={data}
          timer={timer}
          limit="unlimited"
          customerid={
            userDetails.get.customerid ? userDetails.get.customerid : ""
          }
          nextBidAmount={nextBidAmount}
          setNextBidAmount={setNextBidAmount}
          seyProductDetails={seyProductDetails}
          handleClose={toggleModal}
          width={width}
          isMobile={isMobile}
          highestBidder={highestBidder}
          myClientsList={myClientsList}
          myAgent={myAgent}
          from={from}
          setSubmittedPracticeOffer={setSubmittedPracticeOffer}
          submittedPracticeOffer={submittedPracticeOffer}
        />
      </RuumrModal>
    </div>
  );
};

export default PropertyBids;
