import React, { useContext } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import CheckBox from "../../../components/atoms/CheckBox";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import { ModalContext } from "../../../context/ModalContext";

const VerifiedContinue = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { setSignupOpen } = useContext(ModalContext);
  return (
    <div className="formContainer">
      <div className="titleSign">You Are Verified!</div>
      <div className="auth-bottom text-center">
        <img className="phoneLogo" src="/images/verifiedIcon.svg" alt="phone" />
      </div>
      <div className="socialAuthCont">
        <p className="text-center">
          <span className="d-inline-block">
            Thanks for Registering. Ready to start bidding? Good luck!
          </span>
          If you need assistance, we're here. Happy Bidding!
        </p>
      </div>
      {/* <div className="secBtn btnCust mb-3 mt-5">
        <PrimaryButton
          label={"Register To Bid"}
          onClick={() => {
            props.nextStep(null, "rtb");
          }}
        />
      </div> */}
      {/* <div className="secBtn btnCust mb-3">
        <PrimaryButton label={"Sell Your Home"}  onClick={() => props.redirectFunc()} />
      </div> */}
      <div className="secBtn btnCust mb-3">
        <PrimaryButton
          label={"Continue Browsing"}
          onClick={() => {
            setSignupOpen(false);
            history.push(
              location &&
                location.state &&
                !!Object.keys(location.state).length &&
                location.state?.pathname?.split("/")[1] === "property"
                ? location.state.pathname
                : `/search?${global.defaultLocation}`,
            );
          }}
        />
      </div>
    </div>
  );
};

export default VerifiedContinue;
