import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import GlobalContext from "../../../context/GlobalContext";
import useToggle from "../../../utils/useToggle";
import EmailIcon from "@material-ui/icons/Email";
import { Button, Typography } from "@material-ui/core";
import PropertyNotes from "./PropertyNotes";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { sanitizer, showWaring } from "../../../utils";
import Dialog from "../../atoms/Dialog";
import moment from "moment";
import {
  addComment,
  submitOffer,
  confirmCheckout,
  getOfferHistory,
} from "../../../API/propertyDetails";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";

import { addtoCart } from "../../../API/propertyDetails";
import { setDecimalPoint } from "../../../utils";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import isAuthenticate from "../../../utils/auth";
import CustomDialog from "../../atoms/CustomDialog";
import CustomInput from "../../atoms/Inputs/CustomInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import CheckBox from "../../../components/atoms/CheckBox";
import SearchMap from "../Search/SearchMap";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    fontSize: "13px",
    top: "8px",
  },
  customTooltipMobile: {
    fontSize: "13px",
    top: "15px",
  },
}));

let map = null;

const PropertyRightShow = ({
  data,
  agentDetails,
  userDetails,
  agentInfo,
  openFile,
  note,
  propertyId,
  refreshNotes,
  sellerTermsCon,
  currentPropCity,
  collapsed,
  rtbCompleted,
  propertyCollapse,
  rtbFormConfirmation,
  rtbFiles,
  toggleModalBid,
  highestBidder,
  buyTimerNw,
  hideNotes,
  width,
  noComments,
  addProductToCart,
  submittingBid,
  mobile,
  timerNw,
  addFav,
  fav,
  toggleShare,
  clickAskQuestion,
  clickBidNumber,
  tourCount,
  userValue,
  offerTimer,
  submittingBuy,
  submittingOffer,
  soldDetails,
  popupState,
  setBeforePopup,
  setSubmittedPopup,
  submittedPopup,
}) => {
  const { id } = useParams();
  const { userLoggedIn, storeDetails, declinedOffer } =
    useContext(GlobalContext);
  const [sterms, toggleSterms] = useToggle(true);
  const [modalopen, setModalOpen] = useState(false);
  const [property, toggleProperty] = useToggle(true);
  const [buyerAmount, setBuyerAmount] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [emdPrice, setEmdPrice] = useState("");
  const [sellerTooltip, setSellerTooltip] = useState([]);
  const [offerAmount, setOfferAmount] = useState("");
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const [viewPropertyLocations, setViewPropertyLocations] = useState([]);
  const [termsPopup, setTermsPopup] = useState(false);
  const [popSelTerms, setPopSelTerms] = useState(false);
  const [offerPopup, setOfferPopup] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const store_id = window.location.pathname.split("/")[1];
  const [offerHistoryData, setOfferHistoryData] = useState([]);
  const site_id = localStorage.getItem("site_id");
  const [hideBuy, setHideBuy] = useState(true);
  const [isBuyerCommission, setIsBuyerCommission] = useState(false);
  const imgRef = useRef(null);
  const [isClosingCost, setIsClosingCost] = useState(false);
  const [drawSearch, setDrawSearch] = useState(false);
  const [mobileSelectedPropertyId, setMobileSelectedPropetyId] = useState("");
  const [mobilePropertyPopupOpen, setMobilePropertyPopupOpen] = useState(false);
  // const [rtbCompleted, setRtbCompleted] = useState(false);
  // const [modalopenBid, setModelopenBid] = useState(false);
  // const [rtbFiles, setRtbFiles] = useState({});
  // const [highestBidder, setHighestBidder] = useState("");
  const [isImageLoaded, setIsImageLoaded] = useState([]);

  const formValidation = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().email("Enter Valid Email").required("Required"),
  });

  useEffect(() => {
    if (data && Object.keys(data).length > 0 && typeof data !== "undefined") {
      let singleProperty = {
        id: data.id,
        city: data.loc_city,
        state: data.state,
        lat: data.lat,
        lng: data.lng,
      };
      let premium = parseFloat((data.bprice * 6) / 100).toFixed(2);
      let finalPre = 0;
      if (premium <= 10000) {
        setBuyerAmount(10000);
        finalPre = 10000;
      } else {
        setBuyerAmount(premium);
        finalPre = premium;
      }
      let total = parseInt(data.bprice) + parseInt(finalPre);
      setTotalPrice(total);
      setEmdPrice((total * 10) / 100);
      setViewPropertyLocations([singleProperty]);
      if (imgRef.current?.complete) {
        setIsImageLoaded(new Array(matterport.length).fill(true));
      }
    }
  }, [data, window?.google]);
  const handleMapCenterChange = (newCenter, newBound) => {
    let url = new URLSearchParams(location.search);
    url.set("north", newBound.north);
    url.set("south", newBound.south);
    url.set("east", newBound.east);
    url.set("west", newBound.west);
    url.set("lat", newCenter.lat);
    url.set("long", newCenter.lng);
    url.set("cities", "");
    url.set("state", "");
    history.replace({
      pathname: location.pathname,
      search: "?" + url.toString(),
    });
    headerLocation.set({
      id: "",
      cities: "",
      state: "",
      lat: "",
      lng: "",
    });
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    validationSchema: formValidation,
    onSubmit: (values) => {
      localStorage.setItem("fromPage", "buynow");
      history.push({
        pathname: `/signup`,
        state: {
          path: location,
          productType: "buynow",
          from: "buynow",
          values,
          tier: data.listing_tier,
          propertyDtls: { ...data, exisingRtb: rtbFiles },
        },
      });
    },
  });

  const offerFormik = useFormik({
    initialValues: {
      buyer_commission: 0,
      closing_cost: 0,
      firstName: "",
      lastName: "",
      email: "",
      id: data?.id ? data?.id : "",
      rtbType: isAuthenticate() ? "" : "offer",
    },
    validationSchema: formValidation,
    onSubmit: async (values) => {
      let payload = {
        firstname: values.firstName,
        lastname: values.lastName,
        email: values.email,
        id: data?.id ? data?.id : "",
        oprice: offerAmount ? offerAmount : 0,
        buyer_commission: values.buyer_commission,
        closing_cost: values.closing_cost,
      };
      try {
        let res = await submitOffer(payload, site_id);
        if (res && res.status === true) {
          toast.success(res.message);
          setOfferPopup(false);
          setOfferAmount("");
          fetchOfferHistory();
          handleResetFormik();
        } else {
          if (res) {
            toast.warning(res.message);
          }
        }
      } catch (err) {
        toast.warning(err);
        console.error(err, "add tour err");
      }
    },
  });

  const handleResetFormik = () => {
    offerFormik.values.firstName = "";
    offerFormik.values.lastName = "";
    offerFormik.values.email = "";
    offerFormik.values.id = data?.id;
    offerFormik.handleReset();
  };

  const fetchOfferHistory = async () => {
    let offerResult = await getOfferHistory({ id: data?.id + "" }, site_id);
    if (offerResult.status === true) {
      setOfferHistoryData(offerResult.result);
    }
  };

  const contactSellerOnClickHandler = (e) => {
    if (userLoggedIn.get) {
      if (agentDetails && agentDetails.agentEmail) {
        window.location = "mailto:" + agentDetails.agentEmail;
      }
    } else {
      history.push(`/login`);
    }
    e.preventDefault();
  };

  useEffect(() => {
    if (collapsed) {
      toggleSterms(false);
    }
    if (propertyCollapse) {
      toggleProperty(false);
    }
  }, []);

  useEffect(() => {
    if (data?.id) {
      fetchOfferHistory();
    }
  }, [data?.id]);

  useEffect(() => {
    if (submittedPopup === "seller_question") {
      setModalOpen(true);
      setSubmittedPopup(false);
    }
  }, [submittedPopup]);

  const handleMobileTooltip = (ele, ind) => {
    let oldTooltip = [...sellerTooltip];
    let newTooltip = oldTooltip.map((ele, i) => {
      let indEle = { ...ele };
      if (i === ind) {
        indEle.checked = true;
      } else if (i !== ind) {
        indEle.checked = false;
      }
      return indEle;
    });
    setSellerTooltip(newTooltip);
  };
  useEffect(() => {
    if (data && data.sellerTermValues) {
      setSellerTooltip(data.sellerTermValues);
    }
  }, [data]);
  const handleCloseTooltip = (ind) => {
    let oldTooltip = [...sellerTooltip];
    let newTooltip = oldTooltip.map((ele, i) => {
      let indEle = { ...ele };
      if (i === ind) {
        indEle.checked = false;
      }
      return indEle;
    });
    setSellerTooltip(newTooltip);
  };

  const formatPhoneNumbe = (phoneNumber) => {
    if (phoneNumber.substring(0, 2) === "+1") {
      return phoneNumber.substring(2, phoneNumber.length).trim();
    } else {
      return phoneNumber;
    }
  };

  const addSellerTermQuestion = async (text, setText, tag) => {
    let datas = await addComment(
      {
        property_id: id,
        comment: DOMPurify.sanitize(text),
        role: userDetails.role,
        address: userDetails.address,
        companyName: userDetails.companyName,
        isLastComment: 0,
        role: 4,
        topic: tag,
        comment_id: "",
      },
      site_id,
    );
    setText("");
    setModalOpen((prevModel) => !prevModel);
    if (datas.status) {
      toast.success("Your question is under review.");
    }
  };
  const changeOfferText = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, "").replace(/,/g, "");
    setOfferAmount((prevData) =>
      !isNaN(value) && value <= 99999999 ? value : prevData,
    );
  };

  const handleBuyNow = async (id, price) => {
    setTermsPopup(false);
    // addProductToCart(id, price);
    try {
      let res = await confirmCheckout(id, site_id);
      if (res && res.status === true) {
        // showSuccess("Property Purchased Successfully");
        // history.push("/");
        setTermsPopup(false);
        setConfirmPopup(true);
      } else if (res && res.status === false) {
        setTermsPopup(false);
        showWaring(res.message);
        // history.push("/");
      }
    } catch (err) {
      console.error(err, "add tour err");
    }
    // setTermsPopup(false);
  };

  const placeOffer = async (id, price) => {
    try {
      let res = await submitOffer(id, price);
      if (res && res.status === true) {
        toast.success(res.message);
        setOfferPopup(false);
        setOfferAmount("");
      } else {
        if (res) {
          toast.warning(res.message);
        }
      }
    } catch (err) {
      toast.warning(err);
      console.error(err, "add tour err");
    }
  };

  useEffect(() => {
    if (isAuthenticate()) {
      if (userValue?.get["first_name"] != null) {
        offerFormik.setFieldValue("firstName", userValue?.get["first_name"]);
      }
      if (userValue?.get["last_name"] != null) {
        offerFormik.setFieldValue("lastName", userValue?.get["last_name"]);
      }
      offerFormik.setFieldValue("email", userValue?.get["email"]);
    }
  }, [userValue]);

  useEffect(() => {
    offerFormik.setFieldValue("id", data?.id);
  }, [data]);

  useEffect(() => {
    if (
      localStorage.getItem("fromPage") === "buynow" &&
      location?.state?.from === "buynow"
    ) {
      setTimeout(() => {
        setTermsPopup(true);
      }, 1400);
      setTimeout(() => {
        localStorage.setItem("fromPage", "");
      }, 1600);
    }
  }, []);

  const handleUpdateClick = (id, email, type, action) => {
    history.push({
      pathname: `/signup`,
      state: {
        productType:
          type === "bidding" ? "bidding" : type === "buynow" ? "buynow" : "",
        from: type === "buynow" ? "buynow" : "",
        path: location,
        tier: data.listing_tier,
        propertyDtls: { ...data, exisingRtb: rtbFiles },
        action: "userUpdate",
        user_id: id,
        values: {
          email: email,
        },
      },
    });
  };

  const handleUpdateOfferReg = (id, email, type, action) => {
    history.push({
      pathname: `/signup`,
      state: {
        productType:
          type === "bidding" ? "bidding" : type === "buynow" ? "buynow" : "",
        from: type === "buynow" ? "buynow" : "",
        path: location,
        tier: data.listing_tier,
        propertyDtls: { ...data, exisingRtb: rtbFiles },
        action: "",
        user_id: id,
        values: {
          email: email,
        },
      },
    });
  };

  const handleCheckbox = (e, field) => {
    if (field === "buyer_commission") {
      setIsBuyerCommission(e.target.checked);
    }
    if (field === "closing_cost") {
      setIsClosingCost(e.target.checked);
    }
    offerFormik.values[field] = e.target.checked
      ? parseFloat(e.target.value)
      : 0;
  };

  const handleCloseOfferDialog = () => {
    if (popSelTerms) {
      setPopSelTerms(false);
    } else {
      setOfferPopup(false);
    }
    setIsBuyerCommission(false);
    setOfferAmount("");
    setIsClosingCost(false);
  };
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    const cleaned = phoneNumber.replace(/\D/g, "");
    if (cleaned.length !== 10) {
      return phoneNumber;
    }
    return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  };

  useEffect(() => {
    if (offerAmount && offerAmount !== "") {
      let premium = parseFloat((offerAmount * 6) / 100).toFixed(2);
      let finalPre = 0;
      if (premium <= 10000) {
        setBuyerAmount(10000);
        finalPre = 10000;
      } else {
        setBuyerAmount(premium);
        finalPre = premium;
      }
      let total = parseInt(offerAmount) + parseInt(finalPre);
      setTotalPrice(total);
      setEmdPrice((total * 10) / 100);
    } else {
      setBuyerAmount("");
      setTotalPrice("");
      setEmdPrice("");
    }
  }, [offerAmount]);

  const updateBuynowCal = () => {
    let premium = parseFloat((data.bprice * 6) / 100).toFixed(2);
    let finalPre = 0;
    if (premium <= 10000) {
      setBuyerAmount(10000);
      finalPre = 10000;
    } else {
      setBuyerAmount(premium);
      finalPre = premium;
    }
    let total = parseInt(data.bprice) + parseInt(finalPre);
    setTotalPrice(total);
    setEmdPrice((total * 10) / 100);
  };

  const [map, setMap] = useState(null);

  return (
    <div className="property-right">
      <div className="topRightBox">
        {/* {isAuthenticate() ? (
          <>
            {userValue?.get["status"] == "incomplete" && (
              <div className="usrRegCheck">
                <p>
                  Your account is incomplete, please{" "}
                  <a
                    onClick={() =>
                      handleUpdateClick(
                        userValue.get["id"],
                        userValue.get["email"]
                      )
                    }
                  >
                    click here
                  </a>{" "}
                  to update your account info, to proceed with bidding and
                  buynow
                </p>
              </div>
            )}
          </>
        ) : (
          ""
        )} */}
      </div>
      {/* new changes */}
      {/* <span className="notForMobileWrapper"> */}
      {/* <div className="d-flex justify-content-between ntFrmMble">
        <div className="list-inline-item pv-text">
          <span style={{ fontWeight: "500", fontSize: "18px" }}>
            {data.views
              ? data.views > 99999
                ? "99,999+"
                : Number(data.views).toLocaleString()
              : 0}
          </span>
          <span className="pv-ctext"> Views</span>
        </div>
        <div
          className="list-inline-item pv-text"
          onClick={clickAskQuestion}
          style={{ cursor: "pointer" }}
        >
          <span style={{ fontWeight: "500", fontSize: "18px" }}>
            {noComments
              ? noComments > 9999
                ? "9,999+"
                : Number(noComments).toLocaleString()
              : 0}
          </span>
          <span className="pv-ctext">Q&A</span>
        </div>
        <div
          className="list-inline-item pv-text"
          style={{ cursor: "pointer", marginRight: "0px" }}
          onClick={clickBidNumber}
        >
          {!!Number(data.bidcount) ? (
            <>
              {" "}
              <span style={{ fontWeight: "500", fontSize: "18px" }}>
                {data.bidcount
                  ? data.bidcount > 9999
                    ? "9,999+"
                    : Number(data.bidcount).toLocaleString()
                  : 0}
              </span>
              <span className="pv-ctext">Bids</span>
            </>
          ) : (
            <>
              {" "}
              <span style={{ fontWeight: "500", fontSize: "18px" }}>
                {tourCount
                  ? tourCount > 9999
                    ? "9,999+"
                    : Number(tourCount).toLocaleString()
                  : 0}
              </span>
              <span className="pv-ctext">Tours</span>
            </>
          )}
        </div>
        <div
          className="list-inline-item pv-text"
          style={{ cursor: "pointer", marginRight: "0px" }}
          onClick={clickBidNumber}
        >
          {!!Number(data.bidcount) && (
            <>
              {" "}
              <span style={{ fontWeight: "500", fontSize: "18px" }}>
                {data.bidcount
                  ? data.bidcount > 9999
                    ? "9,999+"
                    : Number(data.uniqueBidders).toLocaleString()
                  : 0}
              </span>
              <span className="pv-ctext">Bidders</span>
            </>
          )}
        </div>
      </div> */}
      <div
        className={`d-flex justify-content-between ntFrmMble ${
          width ? "" : "mb-3"
        }`}
      >
        {/* <div className="list-inline-item pv-text flex-white-box">
          <a
            onClick={() =>
              userLoggedIn.get === true
                ? addFav({
                    project_id: id,
                    mobile: mobile.length === 0 ? "" : mobile,
                  })
                : toast.warning("Login to add follow")
            }
          >
            {fav == 0 ? (
              <>
                <span className="material-icons">notifications_off</span>
                Get Bidding Updates
              </>
            ) : (
              <>
                <span className="material-icons">notifications</span>
                Stop Bidding Updates
              </>
            )}
          </a>
        </div>
        <div
          className="list-inline-item pv-text flex-white-box"
          style={{
            cursor: "pointer",
          }}
          onClick={toggleShare}
        >
          <a>
            <img src="/images/share.png" alt="" /> Share
          </a>
        </div> */}
      </div>
      <div className="aboutProperty mb-5">
        <h1 className="detailHeading mb-3">Property Info</h1>
        <div className="detailBox">
          {property && (
            <>
              <table className="table table-striped table-inverse table-hover">
                <tbody>
                  <tr>
                    <td>Address</td>
                    <td>
                      {`${data.loc_address},`}
                      <br />
                      {`${data.loc_city},`} {`${data.state},`}{" "}
                      {`${data.zipcode}`}
                    </td>
                  </tr>
                  {data.bedroom_type ? (
                    <tr>
                      <td>Beds</td>
                      <td>{data.bedroom_type}</td>
                    </tr>
                  ) : null}

                  {data.bathroom ? (
                    <tr>
                      <td>Bath</td>
                      <td>{data.bathroom}</td>
                    </tr>
                  ) : null}

                  {data.sqrtarea ? (
                    <tr>
                      <td>Square Footage</td>
                      <td>{setDecimalPoint(data.sqrtarea)}</td>
                    </tr>
                  ) : null}
                  {data.stories ? (
                    <tr>
                      <td>Lot Size (acre)</td>
                      <td>{data.stories}</td>
                    </tr>
                  ) : null}

                  {data.county ? (
                    <tr>
                      <td>County</td>
                      <td>{data.county}</td>
                    </tr>
                  ) : null}

                  {data.parcel_id_apn ? (
                    <tr>
                      <td>APN</td>
                      <td>{data.parcel_id_apn}</td>
                    </tr>
                  ) : null}

                  {data.Ybuild ? (
                    <tr>
                      <td>Year Built</td>
                      <td>{data.Ybuild}</td>
                    </tr>
                  ) : null}
                  {data.buynow != 1 && data.cash_only_flag === 1 ? (
                    <tr>
                      <td>Cash Only</td>
                      <td>
                        <img src="/images/yesFlag.svg" />
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
              {/* <a className="pv-collapse" onClick={toggleProperty}>
                <i className="fa fa-angle-up"></i> Collapse
              </a> */}
            </>
          )}
        </div>
      </div>
      {data.buynow === 1 ? (
        <div className="pricing mb-5">
          <h1 className="detailHeading mb-3">Pricing Info</h1>
          <div className="detailBox">
            {property && (
              <>
                <table className="table table-striped table-inverse table-hover">
                  <tbody>
                    {data.auction === 1 ? (
                      ""
                    ) : (
                      <tr>
                        <td>List Price</td>
                        <td>${data.mls_price}</td>
                      </tr>
                    )}
                    <tr>
                      <td>{data.bidcount ? "Current Bid" : "Starting Bid"}</td>
                      <td>
                        $
                        {data.bidcount
                          ? setDecimalPoint(data.wprice)
                          : setDecimalPoint(data.sprice)}
                      </td>
                    </tr>
                    {/* <tr>
                    <td>Reserve Price</td>
                    <td>{data.rprice}</td>
                  </tr> */}
                    {data.bprice ? (
                      <tr>
                        <td>Buy It Now Price</td>
                        <td>${setDecimalPoint(data.bprice)}</td>
                      </tr>
                    ) : null}
                    {data.buyers_premium ? (
                      <tr>
                        <td>Buyer's Premium %</td>
                        <td>{data.buyers_premium}%</td>
                      </tr>
                    ) : null}
                    {/* <tr>
                    <td>Buyer's Premium Minimum</td>
                    <td>${data.buyers_premium_minimum}</td>
                  </tr> */}
                    {/* <tr>
                    <td>Buyer's Premium</td>
                    <td>
                      {data.buyers_premium_flag === 1 ? (
                        <img src="/images/yesFlag.svg" />
                      ) : (
                        <img src="/images/noFlag.svg" />
                      )}
                    </td>
                  </tr> */}
                    {data.cash_only_flag === 1 ? (
                      <tr>
                        <td>Cash Only</td>
                        <td>
                          <img src="/images/yesFlag.svg" />
                        </td>
                      </tr>
                    ) : null}
                    {data.real_estate_commission ? (
                      <tr>
                        <td>Real Estate Commission</td>
                        <td>{data.real_estate_commission}%</td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      ) : null}
      <div className="agentInfo mb-5">
        <h1 className="detailHeading mb-3">Agent Info</h1>
        <div className="detailBox">
          {property && (
            <>
              <table className="table table-striped table-inverse table-hover">
                <tbody>
                  {data.listing_agent_name ? (
                    <tr>
                      <td>Name</td>
                      <td>{data.listing_agent_name}</td>
                    </tr>
                  ) : null}
                  {data.listing_agent_email ? (
                    <tr>
                      <td>Email</td>
                      <td>{data.listing_agent_email}</td>
                    </tr>
                  ) : null}
                  {data.listing_agent_phone ? (
                    <tr>
                      <td>Phone Number</td>
                      <td>{formatPhoneNumber(data.listing_agent_phone)}</td>
                    </tr>
                  ) : null}

                  {data.license ? (
                    <tr>
                      <td>License #</td>
                      <td>{data.license}</td>
                    </tr>
                  ) : null}

                  {data.brokerage_name ? (
                    <tr>
                      <td>Brokerage Name</td>
                      <td>{data.brokerage_name}</td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
      <div className="auctioneerInfo mb-5">
        {property && data.auctioneer_name && (
          <>
            <h1 className="detailHeading mb-3">Auction Info</h1>
            <div className="detailBox">
              <table className="table table-striped table-inverse table-hover">
                <tbody>
                  {data.auctioneer_name ? (
                    <tr>
                      <td>Name</td>
                      <td>{data.auctioneer_name}</td>
                    </tr>
                  ) : null}

                  {data.auctioneer_license ? (
                    <tr>
                      <td>License</td>
                      <td>{data.auctioneer_license}</td>
                    </tr>
                  ) : null}

                  {data.auction_firm_name ? (
                    <tr>
                      <td>Auction Firm Name</td>
                      <td>{data.auction_firm_name}</td>
                    </tr>
                  ) : null}

                  {data.auction_firm_license ? (
                    <tr>
                      <td>Auction Firm License</td>
                      <td>{data.auction_firm_license}</td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
      {/* <div
        className="pv-white-box lt-contact ntFrmMble d-none"
        style={{
          backgroundColor: "#F9F5FF",
        }}
      >
        <Button
          style={{
            textTransform: "none",
            textAlign: "left",
            width: "100%",
            paddingLeft: "0px",
            justifyContent: "start",
          }}
          onClick={(e) => {
            e.preventDefault();
            window.open("/agent-crash-course", "_blank");
          }}
        >
          <div className="media">
            <div className="media-body">
              <div className="cname">
                What is {global.site_name || "24 Hour Sold"}?{" "}
              </div>
              <div className="pl-text">Learn how it works</div>
            </div>
          </div>
          <span
            className={
              collapsed
                ? "material-icons mobile-tutorial-arrow"
                : "material-icons desktop-tutorial-arrow"
            }
          >
            arrow_forward_ios
          </span>
        </Button>
      </div> */}

      {/* {data.enable_inspection === "true" && width ? (
        <div className="pv-white-box dl-inspec ntFrmMble">
          <div className="media">
            <div className="media-left">
              <img
                style={{
                  borderRadius: "100px",
                  background: "#fff",
                  objectFit: "cover",
                }}
                src={
                  data.certify_logo
                    ? `${process.env.REACT_APP_IMAGE_URL}uploads/cerify_product/${data.certify_logo}`
                    : "/images/profile_avatar.png"
                }
                alt=""
                width="64"
                height="64"
              />
            </div>
            <div className="media-body">
              {isAuthenticate() ? (
                <div
                  className="dl-inspec-head"
                  style={{ cursor: "pointer" }}
                  onClick={(e) =>
                    openFile(`uploads/cerify_product/${data.certify_name}`)
                  }
                >
                  <a>View Inspection</a>
                </div>
              ) : (
                <div
                  className="dl-inspec-head"
                  style={{ cursor: "pointer" }}
                  onClick={() => setBeforePopup("home_inspection")}
                >
                  <a>View Inspection</a>
                </div>
              )}
              <div className="inspec-by">Inspected By:</div>
              <div className="inspec-by">{data.inspectedBy}</div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )} */}
      {/* <div className="pv-white-box lt-contact ntFrmMble">
        <div className="media">
          <div className="media-left">
            <img
              style={{
                borderRadius: "100px",
                background: "#fff",
                objectFit: "cover",
                width: "64px",
                height: "64px",
              }}
              src={
                agentInfo && agentInfo.avatar
                  ? `${process.env.REACT_APP_IMAGE_URL}uploads/profile/${agentInfo.avatar}`
                  : "/images/profile_avatar.png"
              }
              alt={agentInfo && agentInfo.username}
            />
          </div>
          <div className="media-body">
            <div className="cname">
              {agentInfo && agentInfo.username ? (
                <Link to={`/view-account/${agentInfo.username}`}>
                  {agentInfo && (agentInfo.first_name || agentInfo.last_name)
                    ? agentInfo.first_name + " " + agentInfo.last_name
                    : "No Agent Name"}
                </Link>
              ) : agentInfo && (agentInfo.first_name || agentInfo.last_name) ? (
                agentInfo.first_name + " " + agentInfo.last_name
              ) : (
                "No Agent Information"
              )}
            </div>
            <div className="pl-text">
              {agentInfo && agentInfo.companyName ? agentInfo.companyName : ""}
            </div>
            <div> */}
      {/* <Button
                color="primary"
                disabled={
                  agentDetails && agentDetails.agentEmail ? false : true
                }
                onClick={contactSellerOnClickHandler}
                style={{ textTransform: "none" }}
              >
                {userLoggedIn.get ? (
                  <Typography>Contact</Typography>
                ) : (
                  "Login to Contact"
                )}
              </Button> */}
      {/* {agentInfo && agentInfo.phone ? (
                <a href={`tel:${formatPhoneNumbe(agentInfo.phone)}`}>
                  {formatPhoneNumbe(agentInfo.phone)}
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div> */}
      {/* {data.enable_seller_terms === "true" ? (
        <div className="pv-white-box ki-box ntFrmMble">
          <div
            ref={sellerTermsCon}
            className={`custom-margin key-info-head ${
              sterms ? "clearfix" : "pv-left clearfix"
            }`}
          >
            About Property
            {!sterms && (
              <a className="pv-collapse" onClick={toggleSterms}>
                <i className="fa fa-angle-down"></i> Expand
              </a>
            )}
          </div>
          {sterms && (
            <>
              <p className="seller-terms-dtls">
                {width ? "Hover over" : "Click on"} a term below for details
              </p>
              <table className="table table-striped table-inverse table-hover">
                <tbody>
                  {sellerTooltip &&
                    sellerTooltip.map((ele, i) =>
                      ele.sellerTooltip ? (
                        width ? (
                          <Tooltip
                            title={ele.sellerTooltip}
                            classes={{ tooltip: classes.customTooltip }}
                            placement="top"
                            arrow
                          >
                            <tr>
                              <td>{ele.sellerKey}</td>
                              <td>{ele.sellerValue}</td>
                            </tr>
                          </Tooltip>
                        ) : (
                          <ClickAwayListener
                            onClickAway={() => handleCloseTooltip(i)}
                          >
                            <Tooltip
                              title={ele.sellerTooltip}
                              classes={{
                                tooltip: classes.customTooltipMobile,
                              }}
                              placement="top"
                              arrow
                              open={ele.checked}
                              onClose={(e) => handleCloseTooltip(i)}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                            >
                              <tr onClick={() => handleMobileTooltip(ele, i)}>
                                <td>{ele.sellerKey}</td>
                                <td>{ele.sellerValue}</td>
                              </tr>
                            </Tooltip>
                          </ClickAwayListener>
                        )
                      ) : (
                        <tr>
                          <td>{ele.sellerKey}</td>
                          <td>{ele.sellerValue}</td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>

              <div className="d-flex justify-content-center mb-2 mt-2">
                {isAuthenticate() ? (
                  <button
                    className="btn btn-primary"
                    style={{ textTransform: "none", fontSize: "15px" }}
                    onClick={() => {
                      setModalOpen(true);
                    }}
                    type="button"
                  >
                    Ask a question about terms
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    style={{ textTransform: "none", fontSize: "15px" }}
                    onClick={() => {
                      setBeforePopup("seller_question");
                    }}
                    type="button"
                  >
                    Ask a question about terms
                  </button>
                )}
              </div>

              <a className="pv-collapse" onClick={toggleSterms}>
                <i className="fa fa-angle-up"></i> Collapse
              </a>
            </>
          )}
        </div>
      ) : (
        ""
      )} */}
      {/* <div className="pv-white-box ki-box ntFrmMble">
        <div
          className={`key-info-head ${
            property ? "clearfix" : "pv-left clearfix"
          }`}
        >
          About the Listing
          {!property && (
            <a className="pv-collapse" onClick={toggleProperty}>
              <i className="fa fa-angle-down"></i> Expand
            </a>
          )}
        </div>
        {property && (
          <>
            <table className="table table-striped table-inverse table-hover">
              <tbody>
                {data.aboutProperty &&
                  Object.keys(data.aboutProperty).map((df) => (
                    <tr>
                      <td>{df}</td>
                      <td>{data.aboutProperty[df]}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <a className="pv-collapse" onClick={toggleProperty}>
              <i className="fa fa-angle-up"></i> Collapse
            </a>
          </>
        )}
      </div> */}
      {/* {hideNotes ? (
        ""
      ) : (
        <span className="ntFrmMble">
          <PropertyNotes
            note={note}
            propertyId={propertyId}
            refreshNotes={refreshNotes}
          />
        </span>
      )} */}
      <div className="about-sarea">
        <div className="asa-head">Location</div>
        <div className="pv-loc-map">
          {viewPropertyLocations && viewPropertyLocations?.length !== 0 && (
            <SearchMap
              location={viewPropertyLocations}
              isDraw={false}
              zoomControl={true}
              draggable={true}
              handleMapCenterChange={handleMapCenterChange}
              mobileSelectedPropertyId={mobileSelectedPropertyId}
              setMobileSelectedPropetyId={setMobileSelectedPropetyId}
              setMobilePropertyPopupOpen={setMobilePropertyPopupOpen}
              mobilePropertyPopupOpen={mobilePropertyPopupOpen}
              selectedLocationBound={currentPropCity}
              width={width}
            />
          )}
        </div>
      </div>
      {/* </span> */}
      {popupState != false && (
        <>
          <Dialog
            tag="SellerTerms"
            open={modalopen}
            onClose={(text, setText, tag) =>
              text &&
              sanitizer(text) &&
              addSellerTermQuestion(text, setText, tag)
            }
            close={(e) => setModalOpen((prev) => !prev)}
          />
          <CustomDialog
            closeBtnText={
              popSelTerms ? (
                <>
                  <span
                    className="clsButtonText"
                    onClick={
                      popSelTerms
                        ? () => setPopSelTerms(false)
                        : () => setTermsPopup(false)
                    }
                  >
                    <u>Back</u>
                  </span>
                </>
              ) : null
            }
            open={termsPopup}
            handleClose={
              popSelTerms
                ? () => setPopSelTerms(false)
                : () => setTermsPopup(false)
            }
          >
            {popSelTerms ? (
              <>
                {data.enable_seller_terms === "true" ? (
                  <div className="ki-box">
                    <div
                      ref={sellerTermsCon}
                      className={`custom-margin key-info-head ${
                        sterms ? "clearfix" : "pv-left clearfix"
                      }`}
                    >
                      Seller Terms
                    </div>
                    {sterms && (
                      <>
                        <p className="seller-terms-dtls">
                          {width ? "Hover over" : "Click on"} a term below for
                          details
                        </p>
                        <table className="table table-striped table-inverse table-hover">
                          <tbody>
                            {sellerTooltip &&
                              sellerTooltip.map((ele, i) =>
                                ele.sellerTooltip ? (
                                  width ? (
                                    <Tooltip
                                      title={ele.sellerTooltip}
                                      classes={{
                                        tooltip: classes.customTooltip,
                                      }}
                                      placement="top"
                                      arrow
                                    >
                                      <tr>
                                        <td>{ele.sellerKey}</td>
                                        <td>{ele.sellerValue}</td>
                                      </tr>
                                    </Tooltip>
                                  ) : (
                                    <ClickAwayListener
                                      onClickAway={() => handleCloseTooltip(i)}
                                    >
                                      <Tooltip
                                        title={ele.sellerTooltip}
                                        classes={{
                                          tooltip: classes.customTooltipMobile,
                                        }}
                                        placement="top"
                                        arrow
                                        open={ele.checked}
                                        onClose={(e) => handleCloseTooltip(i)}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                      >
                                        <tr
                                          onClick={() =>
                                            handleMobileTooltip(ele, i)
                                          }
                                        >
                                          <td>{ele.sellerKey}</td>
                                          <td>{ele.sellerValue}</td>
                                        </tr>
                                      </Tooltip>
                                    </ClickAwayListener>
                                  )
                                ) : (
                                  <tr>
                                    <td>{ele.sellerKey}</td>
                                    <td>{ele.sellerValue}</td>
                                  </tr>
                                ),
                              )}
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <div className="buyOfferPopupWrpr confimBuyPopup">
                  <div className="topTitlecontnr subBidPop">
                    {/* <div className="topIconContainer">
                      <span className="material-icons">shopping_cart</span>
                    </div>
                    <h4>Buy Now</h4> */}
                  </div>
                  <div className="bidAmt d-flex justify-content-between">
                    <div className="boldAmt">Buy Price</div>
                    <div>${setDecimalPoint(data?.bprice)}</div>
                  </div>
                  <div className="bidAmt d-flex justify-content-between">
                    <div className="flex-direction-column">
                      <div className="boldAmt w-100">Buyer`s Premium - 6%</div>
                      <div
                        className="boldAmt boldMid w-100"
                        style={{ fontSize: "14px" }}
                      >
                        $10,000 Minimum
                      </div>
                    </div>
                    <div>{buyerAmount ? setDecimalPoint(buyerAmount) : ""}</div>
                  </div>
                  <div className="bidAmt d-flex justify-content-between">
                    <div className="boldAmt">Total Purchase Price</div>
                    <div>{totalPrice ? setDecimalPoint(totalPrice) : ""}</div>
                  </div>
                  <div className="bidAmt d-flex justify-content-between">
                    <div className="flex-direction-column w-100">
                      <div className="boldAmt w-100">EMD Amount - 10%</div>
                      <div
                        className="boldAmt boldMid w-100"
                        style={{ fontSize: "14px" }}
                      >
                        Due at 5 PM the next business Day,after winning the bid.
                        <br />
                        EMD is Non Refundable
                        {/* <span className="material-icons-outlined">info</span> */}
                      </div>
                    </div>
                    <div>{emdPrice ? setDecimalPoint(emdPrice) : ""}</div>
                  </div>
                  <div
                    className="bidAmt mb-2"
                    style={{ maxWidth: "600px", margin: "auto" }}
                  >
                    By submitting an offer you are committing to purchase this
                    property if your offer is accepted and you must sign a
                    purchase sale agreement outling the exact terms of the
                    transaction.View our &nbsp;
                    <a href={global?.storeDetails?.terms_link} target="_blank">
                      investor policy.
                    </a>
                  </div>
                  {/* <div className="mdlBodCntnr">
                    <p className="mdlIfoTxt">
                      You are agreeing to purchase this home at{" "}
                      <span className="buyOfrPrc">
                        ${setDecimalPoint(data?.bprice)}.
                      </span>{" "}
                      Please review the &nbsp;
                      <span
                        className="slrsTerms"
                        onClick={() => setPopSelTerms(true)}
                      >
                        Seller's Terms
                      </span>{" "}
                      before continuing
                    </p>
                  </div> */}
                  {isAuthenticate() ? (
                    <PrimaryButton
                      label="Buy Now"
                      onClick={() => handleBuyNow(data?.id, data?.bprice)}
                    />
                  ) : (
                    <div className="modlFormWrpr">
                      <form className="mdlForm" onSubmit={formik.handleSubmit}>
                        <div className="row">
                          <div className="col-md-6 col-12">
                            <CustomInput
                              value={formik.firstName}
                              label="First Name*"
                              placeholder="Enter first name"
                              name="firstName"
                              size="small"
                              type="text"
                              className={`hh-input ${
                                formik.errors.firstName &&
                                formik.touched.firstName &&
                                "is-invalid"
                              }`}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.firstName &&
                            formik.touched.firstName ? (
                              <div className="invalid-feedback">
                                {formik.errors.firstName}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-md-6 col-12">
                            <CustomInput
                              value={formik.lastName}
                              label="Last Name*"
                              placeholder="Enter last name"
                              size="small"
                              type="text"
                              name="lastName"
                              className={`hh-input ${
                                formik.errors.lastName &&
                                formik.touched.lastName &&
                                "is-invalid"
                              }`}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.lastName &&
                            formik.touched.lastName ? (
                              <div className="invalid-feedback">
                                {formik.errors.lastName}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-md-12 col-12 mt-3">
                            <CustomInput
                              label="Email*"
                              placeholder="Enter valid email address"
                              size="small"
                              type="email"
                              name="email"
                              className={`hh-input ${
                                formik.errors.email &&
                                formik.touched.email &&
                                "is-invalid"
                              }`}
                              value={formik.email}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.email && formik.touched.email ? (
                              <div className="invalid-feedback">
                                {formik.errors.email}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <PrimaryButton label="Next" type="submit" />
                      </form>
                    </div>
                  )}
                </div>
              </>
            )}
          </CustomDialog>

          <CustomDialog
            open={offerPopup}
            closeBtnText={
              popSelTerms ? (
                <>
                  <span
                    className="clsButtonText"
                    onClick={
                      popSelTerms
                        ? () => setPopSelTerms(false)
                        : () => setTermsPopup(false)
                    }
                  >
                    <u>Back</u>
                  </span>
                </>
              ) : null
            }
            handleClose={handleCloseOfferDialog}
          >
            {popSelTerms ? (
              <>
                {data.enable_seller_terms === "true" ? (
                  <div className="ki-box">
                    <div
                      ref={sellerTermsCon}
                      className={`custom-margin key-info-head ${
                        sterms ? "clearfix" : "pv-left clearfix"
                      }`}
                    >
                      Seller Terms
                    </div>
                    {sterms && (
                      <>
                        <p className="seller-terms-dtls">
                          {width ? "Hover over" : "Click on"} a term below for
                          details
                        </p>
                        <table className="table table-striped table-inverse table-hover">
                          <tbody>
                            {sellerTooltip &&
                              sellerTooltip.map((ele, i) =>
                                ele.sellerTooltip ? (
                                  width ? (
                                    <Tooltip
                                      title={ele.sellerTooltip}
                                      classes={{
                                        tooltip: classes.customTooltip,
                                      }}
                                      placement="top"
                                      arrow
                                    >
                                      <tr>
                                        <td>{ele.sellerKey}</td>
                                        <td>{ele.sellerValue}</td>
                                      </tr>
                                    </Tooltip>
                                  ) : (
                                    <ClickAwayListener
                                      onClickAway={() => handleCloseTooltip(i)}
                                    >
                                      <Tooltip
                                        title={ele.sellerTooltip}
                                        classes={{
                                          tooltip: classes.customTooltipMobile,
                                        }}
                                        placement="top"
                                        arrow
                                        open={ele.checked}
                                        onClose={(e) => handleCloseTooltip(i)}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                      >
                                        <tr
                                          onClick={() =>
                                            handleMobileTooltip(ele, i)
                                          }
                                        >
                                          <td>{ele.sellerKey}</td>
                                          <td>{ele.sellerValue}</td>
                                        </tr>
                                      </Tooltip>
                                    </ClickAwayListener>
                                  )
                                ) : (
                                  <tr>
                                    <td>{ele.sellerKey}</td>
                                    <td>{ele.sellerValue}</td>
                                  </tr>
                                ),
                              )}
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              <div className="buyOfferPopupWrpr">
                <div className="topTitlecontnr">
                  <div className="topIconContainer">
                    <span className="material-icons">attach_money</span>
                  </div>
                  <h4>Make An Offer</h4>
                  {/* <h6 className="ofrVale">
                    Offer :{" "}
                    <span className="oferPriceAmt">
                      $
                      {offerAmount > 0
                        ? setDecimalPoint(offerAmount)
                        : setDecimalPoint(0)}
                    </span>
                  </h6> */}
                </div>

                {parseFloat(storeDetails?.store?.buyer_commission || 0) >
                0.1 ? (
                  <div>
                    <CheckBox
                      checked={isBuyerCommission}
                      name="buyer_commission"
                      value={storeDetails?.store?.buyer_commission || 0}
                      onchange={(e) => handleCheckbox(e, "buyer_commission")}
                      label={`Are you willing to pay Buyer Commission (${parseFloat(
                        storeDetails?.store?.buyer_commission || 0,
                      )}% of Offer price)?`}
                    />
                  </div>
                ) : null}

                {parseFloat(storeDetails?.store?.closing_cost || 0) > 0.1 ? (
                  <div>
                    <CheckBox
                      checked={isClosingCost}
                      name="closing_cost"
                      value={storeDetails?.store?.closing_cost || 0}
                      onchange={(e) => handleCheckbox(e, "closing_cost")}
                      label={`Are you willing to pay Closing Cost (${parseFloat(
                        storeDetails?.store?.closing_cost || 0,
                      )}% of Offer price)?`}
                    />
                  </div>
                ) : null}

                <div className="bidAmt d-flex justify-content-between">
                  <div className="boldAmt">Your Offer</div>
                  <div>
                    $
                    {offerAmount > 0
                      ? setDecimalPoint(offerAmount)
                      : setDecimalPoint(0)}
                  </div>
                </div>
                <div className="bidAmt d-flex justify-content-between">
                  <div className="boldAmt">Buyer`s Premium - 6%</div>
                  <div className="boldAmt boldMid">$10,000 Minimum</div>
                  <div>{buyerAmount ? setDecimalPoint(buyerAmount) : ""}</div>
                </div>
                <div className="bidAmt d-flex justify-content-between">
                  <div className="boldAmt">Total Purchase Price</div>
                  <div>{totalPrice ? setDecimalPoint(totalPrice) : ""}</div>
                </div>
                <div className="bidAmt d-flex justify-content-between">
                  <div className="boldAmt">EMD Amount - 10%</div>
                  <div className="boldAmt boldMid">
                    Due at 5 PM the next business Day,after winning the bid.
                    <br />
                    EMD is Non Refundable
                    <span className="material-icons-outlined">info</span>
                  </div>
                  <div>{emdPrice ? setDecimalPoint(emdPrice) : ""}</div>
                </div>
                <div className="bidAmt d-flex justify-content-between">
                  By submitting an offer you are committing to purchase this
                  property if your offer is accepted and you must sign a
                  purchase sale agreement outling the exact terms of the
                  transaction.View our
                  <a href={global?.storeDetails?.terms_link} target="_blank">
                    investor policy.
                  </a>
                </div>
                <div className="modlFormWrpr ofrPopMdl">
                  <div className="modlFormWrpr">
                    {isAuthenticate() ? (
                      <>
                        {userValue?.get["status"] == "incomplete" && (
                          <form
                            className="mdlForm"
                            onSubmit={offerFormik.handleSubmit}
                          >
                            <div className="row">
                              <div className="col-md-6 col-12">
                                <CustomInput
                                  value={offerFormik.values.firstName}
                                  label="First Name*"
                                  placeholder="Enter first name"
                                  name="firstName"
                                  size="small"
                                  type="text"
                                  className={`hh-input ${
                                    offerFormik.errors.firstName &&
                                    offerFormik.touched.firstName &&
                                    "is-invalid"
                                  }`}
                                  onChange={offerFormik.handleChange}
                                  onBlur={offerFormik.handleBlur}
                                />
                                {offerFormik.errors.firstName &&
                                offerFormik.touched.firstName ? (
                                  <div className="invalid-feedback">
                                    {offerFormik.errors.firstName}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-md-6 col-12">
                                <CustomInput
                                  value={offerFormik.values.lastName}
                                  label="Last Name*"
                                  placeholder="Enter last name"
                                  size="small"
                                  type="text"
                                  name="lastName"
                                  className={`hh-input ${
                                    offerFormik.errors.lastName &&
                                    offerFormik.touched.lastName &&
                                    "is-invalid"
                                  }`}
                                  onChange={offerFormik.handleChange}
                                  onBlur={offerFormik.handleBlur}
                                />
                                {offerFormik.errors.lastName &&
                                offerFormik.touched.lastName ? (
                                  <div className="invalid-feedback">
                                    {offerFormik.errors.lastName}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-md-12 col-12 mt-3">
                                <CustomInput
                                  label="Email*"
                                  placeholder="Enter valid email address"
                                  size="small"
                                  type="email"
                                  name="email"
                                  className={`hh-input ${
                                    offerFormik.errors.email &&
                                    offerFormik.touched.email &&
                                    "is-invalid"
                                  }`}
                                  value={offerFormik.values.email}
                                  onChange={offerFormik.handleChange}
                                  onBlur={offerFormik.handleBlur}
                                  disabled={true}
                                />
                                {offerFormik.errors.email &&
                                offerFormik.touched.email ? (
                                  <div className="invalid-feedback">
                                    {offerFormik.errors.email}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-12">
                              <p className="mt-4 text-center">
                                We'll contact you once we hear back from the
                                seller. The seller has 24 hours to accept,
                                decline, or counter your offer.
                              </p>
                            </div>
                            {/* <div className="col-12">
                              <PrimaryButton label="Submit" type="submit" />
                            </div> */}
                          </form>
                        )}
                        <PrimaryButton
                          label="Submit"
                          onClick={offerFormik.handleSubmit}
                        />
                      </>
                    ) : (
                      <form
                        className="mdlForm"
                        onSubmit={offerFormik.handleSubmit}
                      >
                        <div className="row">
                          <div className="col-md-6 col-12">
                            <CustomInput
                              value={offerFormik.values.firstName}
                              label="First Name*"
                              placeholder="Enter first name"
                              name="firstName"
                              size="small"
                              type="text"
                              className={`hh-input ${
                                offerFormik.errors.firstName &&
                                offerFormik.touched.firstName &&
                                "is-invalid"
                              }`}
                              onChange={offerFormik.handleChange}
                              onBlur={offerFormik.handleBlur}
                            />
                            {offerFormik.errors.firstName &&
                            offerFormik.touched.firstName ? (
                              <div className="invalid-feedback">
                                {offerFormik.errors.firstName}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-md-6 col-12">
                            <CustomInput
                              value={offerFormik.values.lastName}
                              label="Last Name*"
                              placeholder="Enter last name"
                              size="small"
                              type="text"
                              name="lastName"
                              className={`hh-input ${
                                offerFormik.errors.lastName &&
                                offerFormik.touched.lastName &&
                                "is-invalid"
                              }`}
                              onChange={offerFormik.handleChange}
                              onBlur={offerFormik.handleBlur}
                            />
                            {offerFormik.errors.lastName &&
                            offerFormik.touched.lastName ? (
                              <div className="invalid-feedback">
                                {offerFormik.errors.lastName}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-md-12 col-12 mt-3">
                            <CustomInput
                              label="Email*"
                              placeholder="Enter valid email address"
                              size="small"
                              type="email"
                              name="email"
                              className={`hh-input ${
                                offerFormik.errors.email &&
                                offerFormik.touched.email &&
                                "is-invalid"
                              }`}
                              value={offerFormik.values.email}
                              onChange={offerFormik.handleChange}
                              onBlur={offerFormik.handleBlur}
                            />
                            {offerFormik.errors.email &&
                            offerFormik.touched.email ? (
                              <div className="invalid-feedback">
                                {offerFormik.errors.email}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-12">
                          <p className="mt-4 text-center">
                            We'll contact you once we hear back from the seller.
                            The seller has 24 hours to accept, decline, or
                            counter your offer.
                          </p>
                        </div>
                        <div className="col-12">
                          <PrimaryButton label="Submit" type="submit" />
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            )}
          </CustomDialog>
          <CustomDialog open={confirmPopup}>
            <div className="confimBuyPopup">
              <div className="spIcn">
                <span className="material-icons">check_circle</span>
              </div>
              <h2>Buy Now Confirmation</h2>
              <p>
                You’re almost there. The seller will be in touch with you
                shortly.
              </p>
              <PrimaryButton
                label="Submit"
                onClick={() => setConfirmPopup(false)}
              />
            </div>
          </CustomDialog>
        </>
      )}
    </div>
  );
};

export default PropertyRightShow;
