import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import Layout from "../../../components/molecules/Layout";
import PropertyLeftTop from "../../../components/molecules/Property/PropertyLeftTop";
import PropertyLeftTopMobile from "../../../components/molecules/Property/PropertyLeftTopMobile";
import PropertyBids from "../../../components/molecules/Property/PropertyBids";
import PropertyComments from "../../../components/molecules/Property/PropertyComments";
import MLSinformation from "../../../components/molecules/Property/MLSinformation";
import SimilarHomes from "../../../components/molecules/SimilarHomes";
import PropertyRight from "../../../components/molecules/Property/PropertyRight";
import socket from "../../../utils/socket";
import { getUserProfileData } from "../../../API/userData";
import {
  getPropertyDetails,
  getBidHistory,
  watchList,
  getOfferHistory,
  addtoCart,
  submitOffer,
  bidding,
  beforeReg,
} from "../../../API/propertyDetails";
import "../../../../src/Themes/Theme1/PropertyView/property.css";
import GlobalContext from "../../../context/GlobalContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RuumrModal from "../../../components/molecules/Modal";
import DOMPurify from "dompurify";
import {
  numberWithCommas,
  setDecimalPoint,
  getTimeInterval,
  onImageError,
  onPropertyImageError,
} from "../../../utils";
import { getAllNotes } from "../../../API/notes";
import { before, filter, findIndex, flatten, orderBy, uniqBy } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import { getSearch } from "../../../API/search";
import Timer from "../../molecules/Timer/timer";

import Button from "@material-ui/core/Button";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import copy from "copy-to-clipboard";
import moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import fileSaver from "file-saver";
import axios from "../../../utils/axiosconfig";
import Dialog from "../../../components/atoms/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import { object } from "yup/lib/locale";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { Helmet } from "react-helmet";
import Fade from "@material-ui/core/Fade";
import { ModalContext } from "../../../context/ModalContext";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import CustomDialog from "../../../components/atoms/CustomDialog";
import { Formik, useFormik } from "formik";
import isAuthenticate from "../../../utils/auth";
import CheckBox from "../../../components/atoms/CheckBox";
import * as Yup from "yup";
import * as mainaxios from "axios";

const Buynow = (props) => {
  let id = props.id;
  const { setLoginOpen, isLoginOpen, setSignupOpen, isSignupOpen } =
    useContext(ModalContext);
  const {
    currentTime,
    comment,
    userDetails,
    bid,
    userLoggedIn,
    userLocation,
    declinedOffer,
    propertyAwarded,
    bidIncrement,
  } = useContext(GlobalContext);
  const Clipboard = useRef();
  const history = useHistory();
  const location = useLocation();
  const [rtbCompleted, setRtbCompleted] = useState(false);
  const [biddingAmount, setBiddingAmount] = useState("");
  const [highestBidder, setHighestBidder] = useState("");
  const [rtbFiles, setRtbFiles] = useState({});
  const [getProductDetails, seyProductDetails] = useState({});
  const [offerHistoryData, setOfferHistoryData] = useState([]);
  const [showComp, setShowComp] = useState(null);
  const [getMatterPort, setMatterPort] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showMatterport, setShowMatterport] = useState(null);
  const [agentDetails, setAgentDetails] = useState({});
  const [timer, setTimer] = useState({});
  const [buyTimer, setBuyTimer] = useState({});
  const [offerTimer, setOfferTimer] = useState({});
  const [getSellerData, setSellerData] = useState({});
  const [notes, setNotes] = useState([]);
  const [lable, setLable] = useState({});
  const [comments, setComments] = useState([]);
  const [noComments, setNoComments] = useState(0);
  const [nextBidAmount, setNextBidAmount] = useState("");
  const [follow, setFollow] = useState(false);
  const [isPropertyRemove, setIsPropertyRemove] = useState(false);
  const [step, setStep] = useState(1);
  const [mobile, setMobile] = useState("");
  const [bidPopup, setBidPopup] = useState(false);
  const [myClientsList, setMyClientsList] = useState([]);
  const [myAgent, setMyAgent] = useState([]);
  const [fav, setFav] = useState(0);
  const [shareFollow, setShareFollow] = useState(false);
  const [similarProperty, setSimilarProperty] = useState([]);
  const [isUserBid, setUserBid] = useState(0);
  const [error, setError] = useState("");
  const [mobileNumberVal, setMobileNumberVal] = useState(false);
  const [agentInfo, setAgentInfo] = useState({});
  const [modalopen, setModalOpen] = useState({});
  const [registerToBidOpen, setRegisterToBidOpen] = useState(false);
  const [currentPropCity, setCurrentPropCity] = useState({});
  const bidContainer = useRef();
  const bookTour = useRef();
  const commentsRef = useRef();
  const sellerTermsCon = useRef(null);
  const [schedulePopup, setSchedulePopup] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [hideBuy, setHideBuy] = useState(true);
  const [offlineMarked, setOfflineMarked] = useState(false);
  const [modalopenBid, setModelopenBid] = useState(false);
  const [tourCount, setTourCount] = useState(0);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const imgRef = React.useRef(null);
  const prevOpen = React.useRef(open);
  const [soldDetails, setSoldDetails] = useState("");
  const [heightChange, setHeightChange] = useState("442");

  const [docUri, setDocUri] = useState("");
  const [beforePopup, setBeforePopup] = useState(false);
  const [submittedPopup, setSubmittedPopup] = useState(false);
  const [validMessage, setMessage] = useState({
    message: "",
    type: "",
  });
  const [submitBidPopup, setSubmitBidPopup] = useState(false);

  const validationArray = Yup.object({
    email: Yup.string().required(`Enter Email`).email("Enter Valid Email"),
    isAgent: Yup.string().required(`Required`),
    need_agent: Yup.string().when("isAgent", {
      is: (isAgent) => isAgent == "false",
      then: Yup.string().required("Required"),
    }),
  });
  const [selectedClient, setSelectedClient] = useState({
    id: "-1",
    first_name: "Buyer does not have a Ruumr account",
    last_name: "",
    email: "",
    property_id: "",
    purchase_power: "",
  });
  const store_id = global?.storeDetails?.id;
  const site_id = global?.storeDetails?.site_id;
  const [ruumrFeeChecked, setRuumrFeeChecked] = useState(false);
  const [refundAmt, setRefundAmt] = useState(false);

  // ("View home inspection", "Ask a question", "Ask about terms", "Get bidding updates", "Schedule tour", "View documents")

  const beforeFormik = useFormik({
    initialValues: {
      email: "",
      isAgent: "",
      rtbCheck: "true",
      propertyID: id,
      listing_tier: "",
      rtbType: "",
      need_agent: "",
    },
    validationSchema: validationArray,
    onSubmit: async (values) => {
      values.rtbType =
        beforePopup == "home_inspection"
          ? "View home inspection"
          : beforePopup == "schedule_tour"
            ? "Schedule tour"
            : beforePopup == "bidding_update"
              ? "Get bidding updates"
              : beforePopup == "view_docs"
                ? "View documents"
                : beforePopup == "ask_question"
                  ? "Ask a question"
                  : beforePopup == "seller_question"
                    ? "Ask about terms"
                    : "";
      values.listing_tier = getProductDetails?.listing_tier;
      let data = await beforeReg(values, site_id);
      if (data && data.success === true) {
        if (data.message != "Account created successfully!") {
          toast.success(data.message);
        }
        setBeforePopup(false);
        localStorage.setItem("userToken", data.token);
        userLoggedIn.set(true);

        // redirect user
        switch (beforePopup) {
          case "home_inspection":
            openFile(
              `uploads/cerify_product/${getProductDetails.certify_name}`,
            );
            break;
          case "schedule_tour":
            if (getProductDetails.book_tour) {
              addTourCount(getProductDetails?.id);
              window.open(encodeURI(getProductDetails.book_tour));
            } else {
              clickSchedule();
            }
            break;
          case "bidding_update":
            addFav({
              project_id: id,
              mobile: mobile.length === 0 ? "" : mobile,
            });
            break;
          case "view_docs":
            window.open(encodeURI(docUri));
            break;
          case "ask_question":
            setSubmittedPopup("ask_question");
            break;
          case "seller_question":
            setSubmittedPopup("seller_question");
            break;
        }
      }
    },
  });

  useEffect(() => {
    let url = new URLSearchParams(location.search);
    if (url.get("showDocs") == "true") {
      setOpen(true);
    }
    if (url.get("showComment") == "true") {
      setTimeout(() => {
        clickAskQuestion();
      }, 2500);
    }
  }, [location]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    if (beforePopup == false) {
      beforeFormik.setFieldValue("email", "");
      beforeFormik.setFieldValue("isAgent", "");
      beforeFormik.setFieldValue("need_agent", "");
      beforeFormik.handleReset();
    }
  }, [beforePopup]);

  useEffect(() => {
    if (beforeFormik?.values?.isAgent === "true") {
      beforeFormik.setFieldValue("need_agent", "");
    }
  }, [beforeFormik.values?.isAgent]);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const width = useMediaQuery("(min-width:1200px)");

  // useEffect(() => {
  //   if (Object.keys(userLocation.get).length !== 0 && id !== undefined) {
  //     getData();
  //     getNotes();
  //   }
  // }, [userLocation.get, id]);
  useEffect(() => {
    if (
      bidIncrement.get &&
      !!Object.keys(bidIncrement.get).length &&
      id === bidIncrement.get.productId
    ) {
      setNextBidAmount(bidIncrement.get["nextBid"]);
    }
  }, [bidIncrement.get, id]);

  const fetchOfferHistory = async () => {
    let offerResult = await getOfferHistory({ id: id + "" }, site_id);
    if (offerResult.status === true) {
      setOfferHistoryData(offerResult.result);
    }
  };

  useEffect(() => {
    if (props.id) {
      getData();
      getNotes();
      fetchOfferHistory();
    }
  }, [props.id]);

  useEffect(() => {
    if (
      declinedOffer.get &&
      declinedOffer.get?.winner &&
      declinedOffer.get.project_id == id
    ) {
      fetchOfferHistory();
      declinedOffer.set({});
    }
  }, [declinedOffer, id]);

  useEffect(() => {
    if (follow === false) {
      setStep(1);
    }
  }, [follow]);

  useEffect(() => {
    if (
      Object.keys(propertyAwarded.get).length !== 0 &&
      propertyAwarded.get["project_id"] == id
    ) {
      if (propertyAwarded.get["winner"] == userDetails.get["id"]) {
        toast.success(
          `Congratulations! You won the property for $${setDecimalPoint(
            propertyAwarded.get["amount"],
          )}`,
        );
      } else {
        if (isUserBid !== 0) {
          toast.warning(`Unfortunately! You have lost this property`);
        }
      }
      seyProductDetails((prv) => ({
        ...prv,
        market_status: "sold",
      }));
      setSoldDetails({
        ...soldDetails,
        highest_amount: propertyAwarded.get["amount"],
        listingFormat: propertyAwarded.get["listingFormat"],
        user_id: propertyAwarded.get["winner"],
      });
      setHighestBidder(propertyAwarded.get["winner"]);
      propertyAwarded.set({});
    }
  }, [userDetails.get, propertyAwarded.get]);

  // useEffect(() => {
  //   if (Object.keys(bid.get).length !== 0) {
  //     if (bid.get["productId"] == id) {
  //       getBidHistorys();
  //     }
  //     if (bid.get["productId"] == id) {
  //       if (!!Object.keys(bid.get).length) {
  //         setHighestBidder(bid.get.highBidder);
  //       }
  //       if (
  //         !!Object.keys(bid.get).length &&
  //         !!Object.keys(userDetails.get).length &&
  //         bid.get["highBidder"] != userDetails.get["id"] &&
  //         isUserBid !== 0 &&
  //         bid.get["noRefresh"] != true
  //       ) {
  //         toast.info("You are losing.");
  //       }
  //       if (
  //         !!Object.keys(bid.get).length &&
  //         !!Object.keys(userDetails.get).length &&
  //         bid.get["highBidder"] === userDetails.get["id"]
  //       ) {
  //         toast.info(bid.get.message);
  //         toast.success("Bid placed successfully!");
  //       }
  //       setNextBidAmount(bid.get["nextBid"]);
  //       seyProductDetails((prv) => ({
  //         ...prv,
  //         wprice: bid.get["wPrice"],
  //       }));
  //     }

  //     if (
  //       bid.get["productId"] == id &&
  //       bid.get["userId"] == userDetails.get["id"]
  //     ) {
  //       setUserBid((prv) => prv + 1);
  //     }
  //     bid.set({});
  //   }
  // }, [userDetails.get, bid.get]);

  useEffect(() => {
    if (
      Object.keys(bid.get).length !== 0 &&
      Number(bid.get.belowFive) === 1 &&
      bid.get["productId"] == id
    ) {
      getProductDetails["date_closed"] = bid.get.belowFiveIncrement;
    }
    if (imgRef.current?.complete) {
      setIsImageLoaded(true);
    }

    Object.keys(getProductDetails).length !== 0 &&
      currentTime.get &&
      setTimer(
        getTimeInterval(
          getProductDetails["date_added"],
          getProductDetails["date_closed"],
          currentTime.get,
          getProductDetails["market_status"],
        ),
      );

    if (Object.keys(getProductDetails).length !== 0 && currentTime.get) {
      if (getProductDetails.buynow == 1) {
        setBuyTimer(
          getTimeInterval(
            getProductDetails["b_date_added"],
            getProductDetails["b_date_closed"],
            currentTime.get,
            getProductDetails["market_status"],
          ),
        );
      }
    }

    if (Object.keys(getProductDetails).length !== 0 && currentTime.get) {
      if (getProductDetails.offer == 1) {
        setOfferTimer(
          getTimeInterval(
            getProductDetails["o_date_added"],
            getProductDetails["o_date_closed"],
            currentTime.get,
            getProductDetails["market_status"],
          ),
        );
      }
    }
  }, [getProductDetails, currentTime.get, bid.get]);

  useEffect(() => {
    if (
      (Object.keys(comment.get).length !== 0) &
      (comment.get["product_id"] === id)
    ) {
      let newComment = comment.get;
      if (newComment.comment_id !== "0") {
        let position = comments.findIndex(
          (comment) => parseInt(comment.id) === parseInt(newComment.comment_id),
        );
        if (position !== -1) {
          let existingComments = comments;
          let existingComment = existingComments[position];
          let rply = existingComment["rply"];
          if (rply) {
            let rplyIndex = rply.findIndex(
              (rply) => parseInt(rply.id) === parseInt(newComment.id),
            );
            if (rplyIndex !== -1) {
              rply[rplyIndex].status = newComment.status;
              existingComment["rplyCount"] =
                newComment.status === "true"
                  ? existingComment["rplyCount"] + 1
                  : existingComment["rplyCount"] - 1;
            } else {
              rply = [{ from: "comments", ...newComment }, ...rply];
              existingComment["rply"] = rply;
              existingComment["rplyCount"] = existingComment["rplyCount"] + 1;
            }
            existingComments[position] = existingComment;
            setComments([...existingComments]);
          } else {
            existingComment["rplyCount"] = existingComment["rplyCount"] + 1;
            let newrply = [{ from: "comments", ...newComment }];
            existingComment["rply"] = newrply;
            existingComments[position] = existingComment;
            setComments([...existingComments]);
          }
        } else {
          let flatArray = flatten(
            filter(
              filter(comments, { from: "comments" }).map((ind) => ind.rply),
              (en) => en !== undefined,
            ),
          );
          let position = flatArray.findIndex(
            (flatArray) =>
              parseInt(flatArray.id) === parseInt(newComment.comment_id),
          );
          if (position !== -1) {
            let rplyCommetParent = flatArray[position];
            let parentCommentPosition = comments.findIndex(
              (comment) =>
                parseInt(comment.id) ===
                  parseInt(rplyCommetParent.comment_id) &&
                comment.from === "comments",
            );
            if (parentCommentPosition !== -1) {
              let existingComments = comments;
              let existingComment = existingComments[parentCommentPosition];
              let rply = existingComment["rply"] || [];
              let rplyposition = rply.findIndex(
                (rply) => parseInt(rply.id) === parseInt(newComment.comment_id),
              );
              if (rplyposition !== -1) {
                let parentRplyComments = rply[rplyposition];
                let replay = parentRplyComments.rply;
                if (replay) {
                  let replayIndex = replay.findIndex(
                    (replay) => parseInt(replay.id) === parseInt(newComment.id),
                  );
                  if (replayIndex !== -1) {
                    replay[replayIndex].status = newComment.status;
                    parentRplyComments["rplyCount"] =
                      newComment.status === "true"
                        ? parentRplyComments["rplyCount"] + 1
                        : parentRplyComments["rplyCount"] - 1;
                  } else {
                    replay = [newComment, ...replay];
                    parentRplyComments["rply"] = replay;
                    parentRplyComments["rplyCount"] =
                      parentRplyComments["rplyCount"] + 1;
                  }
                  rply[rplyposition] = parentRplyComments;
                  existingComment["rply"] = rply;
                  existingComments[position] = existingComment;
                  setComments([...existingComments]);
                } else {
                  let newreplay = [newComment];
                  parentRplyComments["rply"] = newreplay;
                  parentRplyComments["rplyCount"] = 1;
                  rply[rplyposition] = parentRplyComments;
                  existingComment["rply"] = rply;
                  existingComments[position] = existingComment;
                  setComments([...existingComments]);
                }
              }
            }
          }
        }
      } else {
        let position = comments.findIndex(
          (comment) => parseInt(comment.id) === parseInt(newComment.id),
        );
        if (position !== -1) {
          let existingComments = comments;
          existingComments[position].status = newComment.status;
          if (newComment.status === "true") {
            setNoComments((preCount) => preCount + 1);
          } else {
            setNoComments((preCount) => preCount - 1);
          }
          setComments(existingComments);
        } else {
          setComments((existingComments) => [
            { from: "comments", rplyCount: 0, ...newComment },
            ...existingComments,
          ]);
          setNoComments((preCount) => preCount + 1);
        }
      }
    }
  }, [comment.get]);

  const limit = "unlimited";

  const toggleShare = () => setShareFollow((prv) => !prv);

  const getBidHistorys = async () => {
    let data = await getBidHistory({ id }, site_id);
    let bidsArr = data.result;

    seyProductDetails((prv) => ({
      ...prv,
      bidcount: bidsArr.length,
      uniqueBidders: data.uniqueBidders,
    }));
    bidsArr = bidsArr.map((element) => ({
      ...element,
      from: "bids",
    }));

    setComments((prevData) =>
      orderBy(
        [...filter(prevData, { from: "comments" }), ...bidsArr],
        (element) => new Date(element.created_at),
        ["desc"],
      ),
    );
  };

  const getNotes = async () => {
    if (site_id != (null || undefined)) {
      const data = await getAllNotes({ project_id: id }, site_id);
      if (data.status === true) {
        setNotes(data.result);
      }
    }
  };
  const getData = async () => {
    if (site_id != (null || undefined)) {
      let data = await getPropertyDetails(id, site_id);
      if (data && data.status === true) {
        setRtbCompleted(data.result.RtB_Completed);
        setSoldDetails(data.result?.highBidder[0]);
        setHighestBidder(
          !!data.result?.highBidder.length &&
            Object.keys(data.result?.highBidder[0]).length
            ? data.result.highBidder[0].user_id
            : "",
        );
        if (!!data?.result?.RtB_Completed === false) {
          setRtbFiles(
            !!data?.result?.previous_RtB.length &&
              data?.result?.previous_RtB[0] !== null &&
              Object.keys(data?.result?.previous_RtB[0]).length !== 0
              ? data.result.previous_RtB[0]
              : {},
          );
        } else if (!!data?.result?.RtB_Completed === true) {
          setRtbFiles(
            !!data?.result?.existing_RtB.length &&
              data?.result?.existing_RtB[0] !== null &&
              Object.keys(data?.result?.existing_RtB[0]).length !== 0
              ? data.result.existing_RtB[0]
              : {},
          );
        }
        setCurrentPropCity({
          lat: data.result.propertyDetails.lat,
          lng: data.result.propertyDetails.lng,
          city: data.result.propertyDetails.loc_city,
          state: data.result.propertyDetails.state,
        });
        setIsPropertyRemove(data.is_removed === "true");
        setNextBidAmount(data.result.propertyDetails["next_bid"]);
        let propertyDetails = data.result.propertyDetails;
        propertyDetails["matterport"] = propertyDetails["matterport"]
          ? JSON.parse(propertyDetails["matterport"])
          : {};
        propertyDetails["listing_video"] =
          data.result &&
          data.result.propertyDetails &&
          data.result.propertyDetails.listing_video &&
          JSON.parse(data.result.propertyDetails.listing_video).length > 0
            ? JSON.parse(data.result.propertyDetails.listing_video)
            : [];
        setUserBid(propertyDetails.userBidCount);
        setTourCount(propertyDetails.tour_clicks);
        if (data.result.my_clients)
          setMyClientsList(
            data.result.my_clients.filter(
              (client) => client.purchase_power > 0,
            ),
          );
        if (data.result.my_agent) setMyAgent(data.result.my_agent);
        // getSimilarProperty({
        //   page: 1,
        //   cities: [propertyDetails.loc_city],
        //   limit: 10,
        //   status: "",
        // });
        propertyDetails["views"] = data.result.views || 0;
        propertyDetails["uniqueBidders"] = data.result.uniqueBidders || 0;
        setFav(
          data.result.fav ? (data.result.fav["status"] === "true" ? 1 : 0) : 0,
        );
        setAgentInfo(
          data.result.agent === "" || data.result.agent === null
            ? {}
            : data.result.agent,
        );
        setMobile(
          data.result.fav
            ? data.result.fav["mobile"] !== "" ||
              data.result.fav["mobile"] !== null ||
              data.result.fav["mobile"] !== null
              ? data.result.fav["mobile"]
              : ""
            : "",
        );

        let more = JSON.parse(propertyDetails.resource);
        if (more && Array.isArray(more) && more.length !== 0) {
          let dynamicData = more[0] !== undefined ? more[0] : {};
          let staticData = more[1] !== undefined ? more[1] : {};
          propertyDetails["resource"] = { ...staticData, ...dynamicData };
        }

        let terms = JSON.parse(propertyDetails.sellerTerms);
        if (terms && Array.isArray(terms) && terms.length !== 0) {
          let dynamicData = terms[0] !== undefined ? terms[0] : {};
          let staticData = terms[1] !== undefined ? terms[1] : {};
          let sellerToolTips = Object.values(terms[1]);
          let sellerTermsKeys = Object.keys(terms[0]);
          let sellerTermsValues = Object.values(terms[0]);
          let sellerTermsArr = sellerTermsKeys.map((ele, i) => {
            let sellTerm = {};
            sellTerm["sellerKey"] = ele;
            sellTerm["sellerValue"] = sellerTermsValues[i];
            sellTerm["sellerTooltip"] = sellerToolTips[i];
            sellTerm["checked"] = false;
            return sellTerm;
          });
          propertyDetails["sellerTerms"] = { ...staticData, ...dynamicData };
          propertyDetails["sellerTermsTooltip"] = { ...terms[1] };
          propertyDetails["sellerTermValues"] = sellerTermsArr;
        }

        let aboutProperty = JSON.parse(propertyDetails.aboutProperty);
        if (
          aboutProperty &&
          Array.isArray(aboutProperty) &&
          aboutProperty.length !== 0
        ) {
          let dynamicData =
            aboutProperty[0] !== undefined ? aboutProperty[0] : {};
          let staticData =
            aboutProperty[1] !== undefined ? aboutProperty[1] : {};
          propertyDetails["aboutProperty"] = { ...staticData, ...dynamicData };
        }
        seyProductDetails(propertyDetails);
        setIsLoading(false);
        setAgentDetails(
          data.result.propertyDetails["agentOfProperty"]
            ? {
                ...JSON.parse(data.result.propertyDetails["agentOfProperty"]),
                agentUserId: data.result.agent ? data.result.agent.id : "",
              }
            : {},
        );
        let comm = data.result["comments"].map((element) => ({
          ...element,
          from: "comments",
        }));
        let bids = data.result["bids"].map((element) => ({
          ...element,
          from: "bids",
        }));
        setComments(
          orderBy(
            [...comm, ...bids],
            (element) => new Date(element.created_at),
            ["desc"],
          ),
        );
        setNoComments(data.result["commentCount"]);
        setSellerData(
          data.result.propertyDetails["userDetails"]
            ? JSON.parse(data.result.propertyDetails["userDetails"])
            : {},
        );
        let lables = data.result.images.map((data) => data.asset_label);
        let lable = {};
        let allPhotos = data.result.images.map((data) => {
          const url = data.asset_image ? data.asset_image.split(",") : [];
          return url.map((ri) => ({
            original: `${process.env.REACT_APP_IMAGE_URL}uploads/product/${ri}`,
            thumbnail: `${process.env.REACT_APP_IMAGE_URL}uploads/product/${ri}`,
          }));
        });
        lable["All Photos"] = flatten(allPhotos);
        lables.forEach((element) => {
          let resu =
            data.result.images[
              findIndex(data.result.images, { asset_label: element })
            ];
          const url = resu.asset_image ? resu.asset_image.split(",") : [];
          lable[element] = url.map((ri) => ({
            original: `${process.env.REACT_APP_IMAGE_URL}uploads/product/${ri}`,
            thumbnail: `${process.env.REACT_APP_IMAGE_URL}uploads/product/${ri}`,
          }));
        });
        setLable(lable);
        let matterPort = data.result.matterport.map((mp) => ({
          ...mp,
          imgSrc: mp.asset_image.split(","),
        }));
        setMatterPort(matterPort);
        let show = JSON.parse(data.result.propertyDetails.show_matterport);
        setShowMatterport(show);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("online", () => {
      if (offlineMarked) {
        getData();
      }
    });

    window.addEventListener("offline", () => {
      if (!offlineMarked) {
        setOfflineMarked(true);
      }
    });
  }, [offlineMarked]);

  useEffect(() => {
    window.addEventListener("focus", () => {
      if (!socket.connected) {
        getData();
      }
    });

    if (!document.hidden) {
      if (!socket.connected) {
        getData();
      }
    }
  }, [socket.connected, site_id]);

  const openFile = (path) => {
    // fileSaver.saveAs(process.env.REACT_APP_IMAGE_URL + path);
    window.open(process.env.REACT_APP_IMAGE_URL + path, "_blank");
  };

  useEffect(() => {
    if (
      timer &&
      timer?.isCompleted &&
      offerHistoryData.length &&
      highestBidder
    ) {
      let filterOffer = offerHistoryData
        .filter((data) => data.user_id == highestBidder)
        .sort((a, b) => a.id - b.id)
        .map((val) => {
          return val;
        });
      let index = filterOffer.length - 1;
      if (
        filterOffer[index] &&
        filterOffer[index]?.id &&
        filterOffer[index]?.declined
      ) {
        setHideBuy(false);
      } else {
        setHideBuy(true);
      }
    }
  }, [offerHistoryData]);

  const addFav = async (body) => {
    let data = await watchList(body, site_id);
    if (data.status === true) {
      if (data.message === "Removed from watchlist") {
        setFav(0);
        toast.success("Property has been removed from watchlist", {
          preventDuplicate: true,
        });
      } else if (data.message.includes("Watchlist Added")) {
        setFav(1);
        toast.success("Property has been watchlisted", {
          preventDuplicate: true,
        });
      } else if (data.message.includes("Added to watchlist")) {
        setFav(1);
        toast.success("Property has been watchlisted", {
          preventDuplicate: true,
        });
      }
    } else {
      toast.error("Something went wrong");
    }
    // setMobile("");
    setFollow(false);
  };

  const toggleModalBid = () => {
    props.modelUpdate();
  };

  const rtbFormConfirmation = async () => {
    // let res = await axios.post(
    //   "userproperty/Rtb",
    //   {
    //     propertyID: id,
    //     listing_tier: getProductDetails.listing_tier,
    //   },
    //   {
    //     headers: { site_id },
    //   }
    // );
    // try {
    //   if (res && res.data.success) {
    //     return res.data;
    //   }
    // } catch (err) {
    //   console.error(err, "this is err from user property bid");
    // }
  };
  const submittingBid = async () => {
    if (userLoggedIn.get) {
      if (userDetails.get["id"] == getProductDetails.user_id) {
        toast.warning("Can't Bid on own property");
      } else {
        if (
          timer.isCompleted === false &&
          timer.text === "Auction Ends In" &&
          rtbCompleted
        ) {
          // if (userDetails.get["bid_approval"] === "false") {
          //   toast.error("Your bidding priviledge has been revoked");
          // } else {

          // }
          setSubmitBidPopup(true);
        } else if (
          (timer.isCompleted === false &&
            timer.text === "Auction Ends In" &&
            !rtbCompleted) ||
          (timer.isCompleted === false &&
            timer.text === "Bidding Starts In" &&
            !rtbCompleted) ||
          (timer.isCompleted === false &&
            timer.text === "Bidding Starts In" &&
            rtbCompleted &&
            userDetails.get &&
            !!Object.keys(userDetails.get).length)
        ) {
          rtbFormConfirmation();
          if (userDetails.get.customerid == "") {
            setRegisterToBidOpen(true);
          } else {
            history.push({
              pathname:
                (getProductDetails.listing_tier === 1 &&
                  userDetails.get.profiletype === "agent") ||
                (getProductDetails.listing_tier === 1 &&
                  userDetails.get.profiletype === "builder") ||
                (getProductDetails.listing_tier === 1 &&
                  userDetails.get.profiletype === "customer") ||
                (getProductDetails.listing_tier === 2 &&
                  userDetails.get.profiletype === "agent")
                  ? `/registertobid-success`
                  : `/registertobid-form/${id}`,
              state: {
                productType: "bidding",
                path: location,
                tier: getProductDetails.listing_tier,
                propertyDtls: { ...getProductDetails, exisingRtb: rtbFiles },
              },
            });
          }
        } else {
          if (timer.isCompleted === true) {
            toast.warning("Closed");
          } else {
            toast.warning("Auction not yet started");
          }
        }
      }
    } else {
      if (!userLoggedIn.get && timer.isCompleted) {
        toast.warning("Closed");
      } else {
        history.push({
          pathname: `/signup`,
          state: {
            productType: "bidding",
            path: location,
            tier: getProductDetails.listing_tier,
            propertyDtls: { ...getProductDetails, exisingRtb: rtbFiles },
          },
        });
      }
    }
  };

  const submittingBuy = async () => {
    if (userLoggedIn.get) {
      if (userDetails.get["id"] == getProductDetails.user_id) {
        toast.warning("Can't Bid on own property");
      } else {
        if (
          buyTimer.isCompleted === false &&
          buyTimer.text === "Auction Ends In" &&
          rtbCompleted
        ) {
          // if (userDetails.get["bid_approval"] === "false") {
          //   toast.error("Your bidding priviledge has been revoked");
          // } else {

          // }
          toggleModalBid();
        } else if (
          (buyTimer.isCompleted === false &&
            buyTimer.text === "Auction Ends In" &&
            !rtbCompleted) ||
          (buyTimer.isCompleted === false &&
            buyTimer.text === "Bidding Starts In" &&
            !rtbCompleted) ||
          (buyTimer.isCompleted === false &&
            buyTimer.text === "Bidding Starts In" &&
            rtbCompleted &&
            userDetails.get &&
            !!Object.keys(userDetails.get).length)
        ) {
          rtbFormConfirmation();
          localStorage.setItem("fromPage", "buynow");
          if (userDetails.get.customerid == "") {
            setRegisterToBidOpen(true);
          } else {
            history.push({
              pathname:
                (getProductDetails.listing_tier === 1 &&
                  userDetails.get.profiletype === "agent") ||
                (getProductDetails.listing_tier === 1 &&
                  userDetails.get.profiletype === "builder") ||
                (getProductDetails.listing_tier === 1 &&
                  userDetails.get.profiletype === "customer") ||
                (getProductDetails.listing_tier === 2 &&
                  userDetails.get.profiletype === "agent")
                  ? `/registertobid-success`
                  : `/registertobid-form/${id}`,
              state: {
                path: location,
                productType: "buynow",
                from: "buynow",
                tier: getProductDetails.listing_tier,
                propertyDtls: { ...getProductDetails, exisingRtb: rtbFiles },
              },
            });
          }
        } else {
          if (buyTimer.isCompleted === true) {
            toast.warning("Closed");
          } else {
            toast.warning("Auction not yet started");
          }
        }
      }
    } else {
      if (!userLoggedIn.get && buyTimer.isCompleted) {
        toast.warning("Closed");
      } else {
        setLoginOpen(true);
        // localStorage.setItem("fromPage", "buynow");
        // history.push({
        //   pathname: `/signup`,
        //   state: {
        //     path: location,
        //     productType: "buynow",
        //     from: "buynow",
        //     tier: getProductDetails.listing_tier,
        //     propertyDtls: { ...getProductDetails, exisingRtb: rtbFiles },
        //   },
        // });
      }
    }
  };

  const updateProfile = async () => {
    let { cardDetails: newCardInfo, userDetails: userInfo } =
      await getUserProfileData(site_id);
  };

  const submittingOffer = async () => {
    if (userLoggedIn.get) {
      if (userDetails.get["id"] == getProductDetails.user_id) {
        toast.warning("Can't Bid on own property");
      } else {
        if (
          offerTimer.isCompleted === false &&
          offerTimer.text === "Auction Ends In" &&
          rtbCompleted
        ) {
          // if (userDetails.get["bid_approval"] === "false") {
          //   toast.error("Your bidding priviledge has been revoked");
          // } else {

          // }
          toggleModalBid();
        } else if (
          (offerTimer.isCompleted === false &&
            offerTimer.text === "Auction Ends In" &&
            !rtbCompleted) ||
          (offerTimer.isCompleted === false &&
            offerTimer.text === "Bidding Starts In" &&
            !rtbCompleted) ||
          (offerTimer.isCompleted === false &&
            offerTimer.text === "Bidding Starts In" &&
            rtbCompleted &&
            userDetails.get &&
            !!Object.keys(userDetails.get).length)
        ) {
          rtbFormConfirmation();
          if (userDetails.get.customerid == "") {
            setRegisterToBidOpen(true);
          } else {
            history.push({
              pathname:
                (getProductDetails.listing_tier === 1 &&
                  userDetails.get.profiletype === "agent") ||
                (getProductDetails.listing_tier === 1 &&
                  userDetails.get.profiletype === "builder") ||
                (getProductDetails.listing_tier === 1 &&
                  userDetails.get.profiletype === "customer") ||
                (getProductDetails.listing_tier === 2 &&
                  userDetails.get.profiletype === "agent")
                  ? `/registertobid-success`
                  : `/registertobid-form/${id}`,
              state: {
                path: location,
                tier: getProductDetails.listing_tier,
                propertyDtls: { ...getProductDetails, exisingRtb: rtbFiles },
              },
            });
          }
        } else {
          if (offerTimer.isCompleted === true) {
            toast.warning("Closed");
          } else {
            toast.warning("Auction not yet started");
          }
        }
      }
    } else {
      if (!userLoggedIn.get && offerTimer.isCompleted) {
        toast.warning("Closed");
      } else {
        history.push({
          pathname: `/signup`,
          state: {
            path: location,
            tier: getProductDetails.listing_tier,
            propertyDtls: { ...getProductDetails, exisingRtb: rtbFiles },
          },
        });
      }
    }
  };

  const clickSchedule = () => {
    if (
      userLoggedIn.set &&
      userDetails.get["id"] == getProductDetails.user_id
    ) {
      history.push(`/sellercalendar`);
    } else {
      addTourCount(getProductDetails?.id);
      bookTour.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  const clickAskQuestion = () => {
    if (commentsRef.current) {
      commentsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  const clickBidNumber = () => {
    if (bidContainer.current) {
      bidContainer.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  const constructAddressComponent = (
    address,
    zipcode,
    unitNumber,
    city,
    state,
  ) => {
    var addressArray = address?.split(",");
    if (addressArray?.length > 0) {
      if (!!unitNumber?.length) {
        return (
          <>
            <div className="addressLine1">
              {`${addressArray[0]}`}
              <br />
              {`${unitNumber}, ${addressArray[1]}, ${
                addressArray[2]?.trim().split(" ")[0]
              } ${zipcode || ""}`}
            </div>
            {/* <span className="addressLine2">{`${addressArray[1]}, ${
              addressArray[2].trim().split(" ")[0]
            } ${zipcode}`}</span> */}
          </>
        );
      } else if (addressArray?.length === 1) {
        return (
          <div className="addressLine1">
            {`${addressArray[0]}`}
            <br />
            {`${city}, ${state}, ${zipcode || ""}`}
          </div>
        );
      } else {
        return (
          <>
            <div className="addressLine1">{`${addressArray[0]}, ${
              addressArray[1]
            }, ${addressArray[2]?.trim().split(" ")[0]} ${
              zipcode || ""
            }`}</div>{" "}
            {/* <span className="addressLine2">{`${addressArray[1]}, ${
              addressArray[2].trim().split(" ")[0]
            } ${zipcode}`}</span> */}
          </>
        );
      }
    } else {
      return address;
    }
  };

  const addProductToCart = async (id, bprice) => {
    try {
      let res = await addtoCart(id, bprice, site_id);
      if (res && res.status === true) {
        history.push(`/checkout/${id}`);
      } else {
        if (res) {
          toast.warning(res.message);
        }
      }
    } catch (err) {
      toast.warning(err);
      console.error(err, "add tour err");
    }
  };

  const [cancelRequest, setCancelRequest] = useState(undefined);

  let sourceToken = mainaxios.CancelToken.source();

  const checkEmail = async (cancelToken) => {
    const config = {
      cancelToken: cancelToken?.token,
    };
    let { data } = await axios.post(
      process.env.REACT_APP_API + "checkEmailExists",
      {
        email: beforeFormik.values?.email,
      },
      config,
      {
        headers: { site_id },
      },
    );
    if (data.success === true) {
      setMessage({ message: "Email available", type: "success" });
    } else if (data.success === false) {
      setMessage({ message: "Email already taken", type: "error" });
    }
  };

  useEffect(() => {
    if (cancelRequest) {
      cancelRequest.cancel();
    }

    setCancelRequest(sourceToken);
    if (beforeFormik.values?.email != "") {
      checkEmail(sourceToken);
    }
  }, [beforeFormik.values?.email]);

  const closeFunction = () => {
    props.function(false);
  };

  return (
    <>
      <CustomDialog
        handleClose={() => closeFunction()}
        open={props.bidPopup}
        dialogClass="modalViewChan"
        title="Bidding Popup"
      >
        <div className="confimBuyPopup">
          <div className="subBidPop pt-3">
            <div className="row mb-3">
              <div className="col-md-6">
                <div
                  className="property-sub-head"
                  onClick={() =>
                    window.open(
                      `https://www.google.com/maps/search/?api=1&query=${getProductDetails.lat},${getProductDetails.lng}`,
                      "_blank",
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  <div className="text-left">
                    {getProductDetails.house_number
                      ? constructAddressComponent(
                          getProductDetails.loc_address,
                          getProductDetails.zipcode,
                          getProductDetails.house_number,
                        )
                      : constructAddressComponent(
                          getProductDetails.loc_address,
                          getProductDetails.zipcode,
                          "",
                          getProductDetails.loc_city,
                          getProductDetails.state,
                        )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="pd-op-center">
                  <div className="tmrConts">
                    <div className="currtmr">
                      <div className="trm">
                        <span className="pv-timer">
                          <Timer item={getProductDetails} />
                        </span>
                      </div>
                    </div>
                    <div className="currbid">
                      {getProductDetails.bidcount == 0 && !timer.isCompleted ? (
                        <>
                          {getProductDetails.enable_start_price == "false" ? (
                            <>
                              {/* <label>Current Bid:</label>
                                    <h3>$ -</h3> */}
                            </>
                          ) : (
                            <>
                              <span className="bidAmt">
                                ${setDecimalPoint(getProductDetails.sprice)}
                              </span>
                              <span>Starting Price</span>
                            </>
                          )}
                        </>
                      ) : timer.isCompleted ? (
                        <>
                          <span className="bidAmt">
                            ${setDecimalPoint(getProductDetails.bprice)}
                          </span>
                          <span>Buynow Price</span>
                        </>
                      ) : (
                        <>
                          <span className="bidAmt">
                            ${setDecimalPoint(getProductDetails.wprice)}
                          </span>
                          <span>Current Bid</span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {getProductDetails && (
              <PropertyRight
                userValue={userDetails}
                highestBidder={highestBidder}
                data={getProductDetails}
                tourCount={tourCount}
                agentDetails={agentDetails}
                userDetails={getSellerData}
                currentPropCity={currentPropCity}
                note={notes}
                agentInfo={agentInfo}
                openFile={openFile}
                propertyId={id}
                refreshNotes={() => getNotes()}
                buyTimerNw={buyTimer}
                timerNw={timer}
                offerTimer={offerTimer}
                // toggleModalBid={toggleModalBid}
                submittingBid={submittingBid}
                addProductToCart={addProductToCart}
                // rtbFormConfirmation={rtbFormConfirmation}
                rtbCompleted={rtbCompleted}
                // rtbFiles={rtbFiles}
                sellerTermsCon={sellerTermsCon}
                width={width}
                noComments={noComments}
                addFav={addFav}
                mobile={mobile}
                fav={fav}
                toggleShare={toggleShare}
                clickAskQuestion={clickAskQuestion}
                clickBidNumber={clickBidNumber}
                submittingBuy={submittingBuy}
                submittingOffer={submittingOffer}
                soldDetails={soldDetails}
                rtbFiles={rtbFiles}
                setBeforePopup={setBeforePopup}
                setSubmittedPopup={setSubmittedPopup}
                submittedPopup={submittedPopup}
              />
            )}
          </div>
        </div>
      </CustomDialog>
    </>
  );
};

export default Buynow;
