import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleWishlist, getMyWishlist } from "../../../store/slices/wishlist";
import { toast } from "react-toastify";
import "./FavoriteCheckbox.css";

const FavoriteCheckbox = ({ project_id, setLoginOpen }) => {
  let dispatch;
  let wishlist;

  try {
    dispatch = useDispatch();
    wishlist = useSelector((state) => state.wishlist?.wishListItems);
  } catch (error) {
    console.warn("Redux context not available yet");
    return null; // or return a loading state
  }

  // Only proceed if we have dispatch and wishlist
  if (!dispatch || !wishlist) {
    return null; // or return a loading state
  }

  const isExist = wishlist?.find((e) => e.id == project_id);
  const site_id = localStorage.getItem("site_id");

  const toggleFav = () => {
    if (!dispatch) return;

    dispatch(toggleWishlist(project_id)).then((response) => {
      if (response?.payload.status === true) {
        if (response?.payload.message === "Removed from watchlist") {
          toast.success("Property has been removed from watchlist", {
            preventDuplicate: true,
          });
        } else if (response?.payload.message.includes("Watchlist Added")) {
          toast.success("Property has been watchlisted", {
            preventDuplicate: true,
          });
        } else if (response?.payload.message.includes("Added to watchlist")) {
          toast.success("Property has been watchlisted", {
            preventDuplicate: true,
          });
        }
        dispatch(getMyWishlist(site_id));
      } else {
        setLoginOpen(true);
      }
    });
  };

  return (
    <div className="favoriteCheck">
      <input
        id={project_id}
        type="checkbox"
        checked={isExist ? true : false}
        onChange={toggleFav}
      />
      <label htmlFor={project_id}>
        <i className={isExist ? "fa fa-heart" : "far fa-heart"}></i>
      </label>
    </div>
  );
};

export default FavoriteCheckbox;
