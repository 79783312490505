import React, { useEffect, useRef, useState, useContext } from "react";
import SearchCard from "../../../components/atoms/SearchCard";
import GlobalContext from "../../../context/GlobalContext";
import ReactDOM from "react-dom";
import { Button } from "@material-ui/core";
import { throttle } from "lodash";

let map = null;
let markers = [];
let poly = null;
let isMouseDown = false;
let shapes = [];

const GoogleMap = (props) => {
  const mapRef = useRef(null);

  const site_id = global?.storeDetails?.site_id;
  const { currentTime, headerLocation } = useContext(GlobalContext);

  const [propertys, setPropertys] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const currentZoomRef = useRef(null);
  const isUserInteractionRef = useRef(null);
  const initMapLoad = () => {
    if (!map) {
      const mapOptions = {
        zoom: 17,
        zoomControl: props.zoomControl,
        zoomControlOptions: false,
        draggable: props.draggable,
        fullscreenControl: props?.fullscreenControl || false,
        styles: [
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ visibility: "off" }],
          },
        ],
      };

      map = new window.google.maps.Map(
        document.getElementById("map1"),
        mapOptions,
      );
    }
    if (Array.isArray(props.location) && props.location.length) {
      const bounds = new window.google.maps.LatLngBounds();

      props.location.forEach((location) => {
        const position = new window.google.maps.LatLng(
          parseFloat(location.lat),
          parseFloat(location.lng),
        );
        bounds.extend(position);

        const marker = new window.google.maps.Marker({
          position: position,
          map: map,
          title: location.name,
        });

        const infoWindow = new window.google.maps.InfoWindow({
          content: `<div><h3>${location.name}</h3><p><a href="${window.location.origin}/search?location=${location.id}" target="_blank">More Info</a></p></div>`,
        });

        // marker.addListener("click", () => {
        //   infoWindow.open(map, marker);
        // });
      });
      map.fitBounds(bounds);

      // Set a maximum zoom level
      const listener = window.google.maps.event.addListener(
        map,
        "bounds_changed",
        () => {
          if (map.getZoom() > 15) {
            // Adjust the maximum zoom level as needed
            map.setZoom(15);
          }
          window.google.maps.event.removeListener(listener);
        },
      );
    }
  };

  useEffect(() => {
    if (window.google && window?.google?.maps) {
      initMapLoad();
    } else {
      if (!map) {
        window.initMapLoad = initMapLoad;
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&callback=initMapLoad`;
        script.async = true;
        script.defer = true;
        script.onerror = () => {
          console.error("Google Maps script loading error");
        };
        document.head.appendChild(script);
      }
    }

    return () => {
      if (map) {
        const googleMapsScript = document.querySelector(
          'script[src*="maps.googleapis.com"]',
        );
        if (googleMapsScript) {
          googleMapsScript.remove();
        }
        // if (window.google) {
        //   delete window.google;
        // }
        map = null;
      }
    };
  }, []);

  useEffect(() => {
    if (window.google && window.google.maps) {
      initMapLoad();
    }
  }, [props.location]);

  return (
    <>
      {/* <button onClick={applyBound}>Apply</button> */}
      <div
        id="map1"
        ref={mapRef}
        style={{
          width: props?.width || "auto",
          height: "100%",
        }}
      ></div>
    </>
  );
};

export default GoogleMap;
