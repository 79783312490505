import React, { useEffect, useState, useContext, useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import GlobalContext from "../../../context/GlobalContext";
import moment from "moment";
import axios from "../../../utils/axiosconfig";
import CustomPhone from "../../../components/atoms/Inputs/CustomPhone";
import { saveProfileData } from "../../../API/userData";
import {
  phoneNumberValid,
  normalizePhoneNumberInput,
} from "../../../utils/FormikUtils";
import { toast } from "react-toastify";
import Tooltip from "@material-ui/core/Tooltip";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import CustomInputs from "../../../components/atoms/Inputs/CustomTextArea";
import Loader from "../../../components/atoms/Loader";

const ProfileForm = (props) => {
  const { userDetails, userLocation } = useContext(GlobalContext);
  const [currentCountry, setCurrentCountry] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const site_id = localStorage.getItem("site_id");

  useEffect(() => {
    Object.keys(userLocation.get).length !== 0 &&
      setCurrentCountry(userLocation.get["countryCode"].toLowerCase());
  }, [userLocation.get]);

  const profileSchema = Yup.object({
    phone: Yup.string()
      .notRequired()
      .test("checkPhone", "Enter valid mobile phone", (value) => {
        if (value && value.length !== 0) {
          return phoneNumberValid(value);
        }
        return true;
      }),

    // company: Yup.string()
    //   .max(50, "Maximum 50 characters")
    //   .matches(/^[A-Za-z0-9_. -]+$/, "Enter valid company name"),
    // address: Yup.string()
    //   .max(100, "Maximum 100 characters")
    //   .test("checkScript", "Enter valid address", (value) => {
    //     if (value && value.length !== 0) return !value.includes("<script>");
    //   }),
    aboutme: Yup.string()
      .max(1000, "Maximum 1000 characters")
      .test("checkScript", "Enter valid description", (value) => {
        if (value && value.length !== 0) return !value.includes("<script>");
      }),
    avatar: Yup.mixed(),
  });

  const updateProfile = async (values) => {
    const formData = new FormData();
    formData.append("phone", values.phone);
    formData.append("companyName", values.company);
    formData.append("address", values.address);
    formData.append("aboutme", values.aboutme);
    if (typeof values.avatar === "string") {
      formData.append("avatar", values.avatar);
    } else {
      formData.append("profile_image", values.avatar);
    }
    setIsLoading(true);
    try {
      let data = await saveProfileData(formData, site_id);
      if (data) {
        let userData = userDetails.get;
        userData["companyName"] = values.company;
        userData["phone"] = values.phone;
        userData["address"] = values.address;
        userData["aboutme"] = values.aboutme;
        userData["avatar"] = data.avatar;
        userDetails.set({ ...userData });
        toast.success("Profile updated successfully");
        props.edit(false);
      } else {
        toast.warning("Something went wrong");
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("An error occurred while updating the profile");
    }
  };

  const formRef = useRef();
  return (
    Object.keys(userDetails.get).length !== 0 && (
      <div className="form-wrapper position-relative">
        {isLoading && (
          <div className="loading-overlay profile-overlay">
            <Loader />
          </div>
        )}
        <Formik
          initialValues={{
            username: userDetails.get["user_name"],
            email: userDetails.get["email"],
            phone: userDetails.get["phone"] || "",
            company: userDetails.get["companyName"] || "",
            address: userDetails.get["address"] || "",
            aboutme: userDetails.get["aboutme"] || "",
            avatar: userDetails.get["avatar"] || "",
          }}
          innerRef={formRef}
          enableReinitialize={true}
          validationSchema={profileSchema}
          onSubmit={(values) => {
            updateProfile(values);
          }}
        >
          {({
            errors,
            touched,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form
              className="profile-form-wrapper form py-2 m-0"
              onSubmit={handleSubmit}
              noValidate
            >
              <div className="profile-card-wrapper">
                <div className="media pcd-media">
                  <div className="media-left">
                    <div className="upload-photo">
                      {values.avatar && (
                        <img
                          src={
                            typeof values.avatar === "string"
                              ? process.env.REACT_APP_IMAGE_URL +
                                "uploads/profile/" +
                                values["avatar"]
                              : URL.createObjectURL(values["avatar"])
                          }
                          className="prflImg"
                        />
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          if (!e.target.files) {
                            return;
                          }
                          setFieldValue("avatar", e.target.files["0"], true);
                        }}
                      />
                      <label>Click to upload profile photo</label>
                    </div>
                  </div>

                  <div className="media-body">
                    <div className="about-box">
                      <div className="am-head">About me</div>
                      <div className="pc-margin-bottom">
                        <CustomInputs
                          name="aboutme"
                          className="about-input"
                          value={values.aboutme}
                          onChange={handleChange}
                          placeholder="Tell us who you are and why you love real estate!"
                        />
                      </div>
                      {errors.aboutme && touched.aboutme ? (
                        <div className="invalid-feedback">{errors.aboutme}</div>
                      ) : null}
                      <div className="joined-date pc-margin-bottom">
                        {userDetails.get["created"]
                          ? `Joined ${moment(userDetails.get["created"]).format(
                              "MMM",
                            )}. ${moment(userDetails.get["created"]).format(
                              "YYYY",
                            )}`
                          : ""}
                      </div>
                    </div>

                    {/* <div className="row">
                      <div className="col-md-6">
                        <div className="pcd-name mb-2">
                          <CustomInput
                            placeholder="Username"
                            className="about-input"
                            value={values.username}
                            name="username"
                            disabled={true}
                          />
                        </div>
                        <div className="joined-date pc-margin-bottom">
                          {userDetails.get["created"]
                            ? `Joined ${moment(
                                userDetails.get["created"]
                              ).format("MMM")}. ${moment(
                                userDetails.get["created"]
                              ).format("YYYY")}`
                            : ""}
                        </div>

                        <div className="profile-ep-text mt-3">
                          <CustomInput
                            label=""
                            type="tel"
                            value={values.phone}
                            name="phone"
                            size="small"
                            inputProps={{ maxlength: 14 }}
                            onChange={(e) => {
                              setFieldValue(
                                "phone",
                                normalizePhoneNumberInput(
                                  e.target.value,
                                  values.phone
                                )
                              );
                            }}
                            onBlur={handleBlur}
                            className={`hh-input ${
                              errors.phone && touched.phone && "is-invalid"
                            }`}
                            placeholder="Phone"
                          />
                          {errors.phone && touched.phone ? (
                            <div className="invalid-feedback">
                              {errors.phone}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6 text-md-right">
                        <div className="padr-txt mb-4">
                          <CustomInput
                            placeholder="Email"
                            className="about-input"
                            name="email"
                            value={values.email}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="text-right mt-4 fp-btn">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isLoading}
                >
                  SAVE CHANGES
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    )
  );
};

export default ProfileForm;
