import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import GlobalContext from "../../../context/GlobalContext";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import { showError, showWaring } from "../../../utils";
import axios from "../../../utils/axiosconfig";
import {
  normalizePhoneNumberInput,
  phoneNumberValid,
} from "../../../utils/FormikUtils";
import CheckBox from "../../../components/atoms/CheckBox";
import CustomDialog from "../../../components/atoms/CustomDialog";
import { ModalContext } from "../../../context/ModalContext";
import TermsPopup from "../Popups/TermsPopup";
const SignUpSecondStep = (props) => {
  const site_id = global?.storeDetails?.site_id;
  const signupData = JSON.parse(localStorage?.getItem("regUser"));
  const { isTermsOpen, handleTermsPopup } = useContext(ModalContext);
  const { loading } = useContext(GlobalContext);
  const formik = useFormik({
    initialValues: {
      email: props.formik.values.email || "",
      // user_name: "",
      first_name: signupData?.first_name || "",
      last_name: signupData?.last_name || "",
      phone: "",
      password: "",
      confirmpassword: "",
      terms: 0,
      agent: 0,
      role: "4",
      profiletype: "customer",
    },
    validationSchema: Yup.object({
      // user_name: Yup.string()
      //   .max(50, "Maximum 50 characters")
      //   .required("Enter your username")
      //   .notOneOf(
      //     [Yup.ref("email"), null],
      //     "Your Username Name cannot be your email"
      //   )
      //   .matches(/^[A-Za-z0-9-_. ]+$/, "Enter valid username"),
      email: Yup.string()
        .max(100, "Maximum 100 characters")
        .email("Invalid email format")
        .required("Enter your Email ID"),
      first_name: Yup.string()
        .max(30, "Maximum 30 characters")
        .required("Enter your First Name")
        .matches(/^[A-Za-z ]+$/, "Enter valid name"),
      last_name: Yup.string()
        .max(30, "Maximum 30 characters")
        .required("Enter your Last Name")
        .matches(/^[A-Za-z ]+$/, "Enter valid name"),
      phone: Yup.string()
        .required("Phone number is required")
        .test("checkPhone", "Enter valid mobile phone", phoneNumberValid),
      password: Yup.string()
        .max(50, "Maximum 50 characters")
        .min(8, "Minimum 8 characters")
        .required("Password is required"),
      confirmpassword: Yup.string()
        .max(50, "Maximum 50 characters")
        .min(8, "Minimum 8 characters")
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm password is required"),
      terms: Yup.number()
        .required("Please accept the terms and conditions")
        .oneOf([1, "1"], "Please accept the terms and conditions"),
      agent: Yup.number(),
    }),
    onSubmit: async (values) => {
      try {
        let { data } = await axios.post(
          "checkEmailExists",
          {
            // user_name: values.user_name,
            email: values.email,
          },
          {
            headers: { site_id },
          },
        );
        if (data?.success === true) {
          values.role = values.agent === 1 ? "2" : "4";
          values.profiletype = values.agent === 1 ? "agent" : "customer";
          values.first_name = values.first_name.trim();
          values.last_name = values.last_name.trim();
          // values.user_name = values.user_name.trim();

          let { data: sendOtp } = await axios.post(
            "sendPhoneVerifyCode",
            {
              ...values,
              email: values.email,
              sendToken: true,
            },
            {
              headers: { site_id },
            },
          );

          if (sendOtp?.success) {
            props.nextStep(values);
            localStorage.removeItem("regUser");
          } else {
            showError(sendOtp?.message || "Something went wrong!");
          }

          // loading.set(true);
          // const { data: registerData } = await axios.post(
          //   "sellersignup",
          //   values,
          //   {
          //     headers: { site_id },
          //   }
          // );

          // if (registerData?.success === "yes") {
          //   loading.set(false);
          //   props.nextStep(values, "register_success", registerData);
          // } else {
          //   showError(registerData?.reason || "Something went wrong!");
          // }
        } else {
          showError(data?.message || "Something went wrong!");
        }
      } catch (error) {
        showError(data?.message || "Something went wrong!");
      }
    },
  });

  return (
    <div className="formContainer">
      <div className="titleSign">Register</div>
      <form className="form m-0">
        <>
          <div className="form-group mt-3">
            <CustomInput
              label="Email*"
              name="email"
              placeholder="Enter Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {/* {formik.touched.email && formik.errors.email ? (
              <div className="invalid-feedback">{formik.errors.user_name}</div>
            ) : null} */}
          </div>
          <div className="form-group mt-3">
            <CustomInput
              label="First Name"
              name="first_name"
              placeholder="Enter First Name"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.first_name && formik.errors.first_name ? (
              <div className="invalid-feedback">{formik.errors.first_name}</div>
            ) : null}
          </div>
          <div className="form-group mt-3">
            <CustomInput
              label="Last Name"
              name="last_name"
              placeholder="Enter Last Name"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.last_name && formik.errors.last_name ? (
              <div className="invalid-feedback">{formik.errors.last_name}</div>
            ) : null}
          </div>
          <div className="form-group mt-3">
            <CustomInput
              label="Mobile Number"
              name="phone"
              placeholder="Enter Mobile Number"
              value={formik.values.phone}
              onChange={(e) => {
                formik.setFieldValue(
                  "phone",
                  normalizePhoneNumberInput(
                    e.target.value,
                    formik.values.phone,
                  ),
                );
              }}
              onBlur={formik.handleBlur}
              required
            />
            {formik.touched.phone && formik.errors.phone ? (
              <div className="invalid-feedback">{formik.errors.phone}</div>
            ) : null}
          </div>
          <div className="form-group mt-3">
            <CustomInput
              label="Password"
              value={formik.values.password}
              name="password"
              size="small"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter Password"
              required
            />
            {formik.errors.password && formik.touched.password ? (
              <div className="invalid-feedback">{formik.errors.password}</div>
            ) : null}
          </div>
          <div className="form-group mt-3">
            <CustomInput
              label="Confirm Password"
              value={formik.values.confirmpassword}
              name="confirmpassword"
              size="small"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter Confirm Password"
              required
            />
            {formik.errors.confirmpassword && formik.touched.confirmpassword ? (
              <div className="invalid-feedback">
                {formik.errors.confirmpassword}
              </div>
            ) : null}
          </div>
          <div className="form-group mt-3">
            <CheckBox
              label="Are you a Real Estate Agent ?"
              onchange={(e) => {
                formik.setFieldValue(
                  "agent",
                  formik.values.agent === 1 ? 0 : 1,
                );
              }}
              onBlur={formik.handleBlur}
              name="agent"
              value="1"
              checked={formik.values.agent === 1 || formik.values.agent === "1"}
            />
            {formik.errors.agent && formik.touched.agent ? (
              <div className="invalid-feedback">{formik.errors.agent}</div>
            ) : null}
          </div>
          <div className="form-group mt-3">
            <CheckBox
              label={
                <span>
                  I agree to{" "}
                  <a
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={handleTermsPopup}
                    target="_blank"
                  >
                    Terms of Use
                  </a>
                </span>
              }
              onchange={(e) => {
                formik.setFieldValue(
                  "terms",
                  formik.values.terms === 1 ? 0 : 1,
                );
              }}
              onBlur={formik.handleBlur}
              name="terms"
              value="1"
              checked={formik.values.terms === 1 || formik.values.terms === "1"}
            />
            {formik.errors.terms && formik.touched.terms ? (
              <p className="invalid-feedback">
                Please accept the terms and conditions
              </p>
            ) : null}
          </div>
        </>
        {isTermsOpen && <TermsPopup />}
        <div className="secBtn">
          <PrimaryButton label={"SUBMIT"} onClick={formik.handleSubmit} />
        </div>
      </form>
    </div>
  );
};

export default SignUpSecondStep;
