import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Layout from "../Layout";
import "./authLayout.css";
import GlobalContext from "../../../context/GlobalContext";

const AuthLayout = (props) => {
  const { storeDetails } = useContext(GlobalContext);
  const history = useHistory();
  const store_data = { store: global?.storeDetails };
  return (
    <Layout>
      <div className={`container auth-container ${props.cutomClass}`}>
        {props.back && (
          <div className="btp-link pt-2">
            <Link onClick={() => history.goBack()} className="back-link">
              <i className="fa fa-angle-left"></i> <span>Back</span>
            </Link>
          </div>
        )}
        <div className="auth-wrapper">
          {!props.hide_logo && (
            <img
              src={
                store_data?.store?.id
                  ? `${process.env.REACT_APP_IMAGE_URL}uploads/site_logos/site_${store_data?.store?.id}/${store_data?.store?.logo}`
                  : global.logo_path || "/images/mylogo.png"
              }
              alt=""
              className="d-block m-auto auth-layout-logo"
            />
          )}
          <h3 className="auth-title">{props.title}</h3>
          <div className="auth-form-wrapper">{props.children}</div>
        </div>
      </div>
    </Layout>
  );
};

export default AuthLayout;
